import { API } from '../config'
import errorHandler from "../components/errorHandler"

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//ADD COMMENT
/* 
    * idClient: number
    * idQuotation: number
    * comment: string
*/
export const AddComment = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            idClient: data.idClient,
            idQuotation: data.idQuotation,
            comment: data.comment
        }

        const response = await fetch (`${API.BASEURL}/followup/addComment`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//GET COMMENTS BY ID QUOTATION
/* 
    * idQuotation: number
*/
export const GetComment = async (idQuotation) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/followup/getComments/${idQuotation}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}