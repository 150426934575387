import React, { useEffect, useState } from "react"

//Material UI imports
import {
    Container,
    CssBaseline,
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Components imports
import NavBar from "../components/NavBarComponent/NavBarComponent.jsx"
import { useNavigate, useParams } from "react-router-dom"

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//Services import
import * as QuotationsService from '../api/quotations'
import * as ContractService from '../api/contracts'

// Import component to stepper
import NewContractStepOne from "../components/ContractSteps/new_contract_step_one"
import NewContractStepTwo from "../components/ContractSteps/new_contract_step_two"

//Moment import
import moment, { relativeTimeRounding } from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({
    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 5,
        fontWeight: 800
    },

    buttonNew: {
        backgroundColor: "#5C5470",
    }
});

const generateRandomString = (num) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
}

const steps = ["Detalles del evento", "Datos comerciales"];

const NewContract = () => {
    const { id } = useParams()
    let navigate = useNavigate()
    const [title, setTitle] = useState('Crear Contrato')
    const [buttonEnd, setButtonEnd] = useState('Crear contrato')

    /* Functions to stepper */
    const [activeStep, setActiveStep] = useState(0)

    const handleNext = async () => {
        if (activeStep === 0) {
            if (
                // Validation of contract details
                contractDetails.customer !== null &&
                contractDetails.eventDate !== '' &&
                contractDetails.quotation !== null &&
                //code !== ''
                //validateRFC(contractDetails.rfc)
                contractDetails.customerName !== '' &&
                contractDetails.rfc !== '' &&
                contractDetails.address !== '' &&
                contractDetails.eventPlace !== ''
            ) {
                if (validateRFC(contractDetails.rfc)) {
                    //console.log(contractDetails)
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setOpenAlert(true);
                    setDataAlert({
                        message: 'RFC inválido',
                        type: 'error'
                    })
                }

            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                })
            }
        }
        else if (activeStep === (steps.length - 1)) {

            if (
                // Validation of comercial details
                comercialDetails.totalGuests !== 0 &&
                comercialDetails.adultsAmount !== 0 &&
                comercialDetails.adultExtraCost !== 0 &&
                comercialDetails.eventDuration !== 0 &&
                comercialDetails.extraDurationCost !== 0 &&
                comercialDetails.totalEventCost !== 0 &&
                // comercialDetails.waiterBarServiceTime !== 0 &&
                // comercialDetails.waiterBarExtraHourCost !== 0 &&
                // Validation of payment details
                paymentDetails.paymentsAmount !== 0 &&
                paymentDetails.prepaymentPercentage !== 0 &&
                paymentDetails.depositAmount !== 0 &&
                // Validation of payments list
                paymentsList.length !== 0
            ) {
                //Validation of customersAmount
                const amount = comercialDetails.adultsAmount + comercialDetails.childrenAmount + comercialDetails.teensAmount
                if (comercialDetails.totalGuests < amount || comercialDetails.totalGuests > amount) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "La cantidad de invitados es distinta a la indicada en la cotización",
                        type: 'error'
                    })
                } else if ((comercialDetails.teensAmount > 0 && comercialDetails.teenExtraCost === 0) || (comercialDetails.childrenAmount > 0 && comercialDetails.childrenExtraCost === 0)) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Existen campos obligatorios sin llenar",
                        type: 'error'
                    })
                } /* else if ((comercialDetails.waiterBarServiceTime > 0 && comercialDetails.waiterBarExtraHourCost === 0)) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Existen campos obligatorios sin llenar",
                        type: 'error'
                    })
                } */ else if(paymentsList.some(el => (el.paymentDueDate === null || !moment(el.paymentDueDate, 'YYYY-MM-DD').isValid()))) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Existen pagos sin fecha limite",
                        type: 'error'
                    })
                } else if(validatePaymentsQty(paymentsList, comercialDetails.totalEventCost)) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Los pagos no coinciden con el costo total del evento",
                        type: 'error'
                    })
                } else {
                    // console.log("Contract Details: ", contractDetails)
                    // console.log("Comercial Details: ", comercialDetails)
                    // console.log("Payment Details: ", paymentDetails)
                    // console.log("Payments List: ", paymentsList)

                    paymentsList.forEach((payment) => { delete payment.paymentId })
                    paymentsList.forEach((payment) => { delete payment.paymentNumber })

                    // console.warn("Se crea contrato")

                    setTextLoader('Creando contrato')
                    setOpenLoader(true)

                    const response = await ContractService.CreateContract(contractDetails, comercialDetails, paymentDetails, paymentsList)

                    if (response.success) {
                        setOpenAlert(true)
                        setDataAlert({
                            message: 'Contrato creado exitosamente',
                            type: 'success'
                        })

                        navigate(`/contratos/detalles/${response.data}`);
                    } else {
                        setOpenAlert(true)
                        setDataAlert({
                            message: response.message,
                            type: 'error'
                        })
                    }
                }
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                })
            }

            setOpenLoader(false)
            setTextLoader('')
        }
    }

    const handleNextUpdate = async () => {
        if (activeStep === 0) {
            if (
                // Validation of contract details
                contractDetails.customer !== null &&
                contractDetails.eventDate !== '' &&
                contractDetails.quotation !== null &&
                //code !== ''
                //validateRFC(contractDetails.rfc)
                contractDetails.customerName !== '' &&
                contractDetails.rfc !== '' &&
                contractDetails.address !== '' &&
                contractDetails.eventPlace !== ''
            ) {
                if (validateRFC(contractDetails.rfc)) {
                    //console.log(contractDetails)
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setOpenAlert(true);
                    setDataAlert({
                        message: 'RFC inválido',
                        type: 'error'
                    })
                }

            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                })
            }
        }
        else if (activeStep === (steps.length - 1)) {

            if (
                // Validation of comercial details
                comercialDetails.totalGuests !== 0 &&
                comercialDetails.adultsAmount !== 0 &&
                comercialDetails.adultExtraCost !== 0 &&
                comercialDetails.eventDuration !== 0 &&
                comercialDetails.extraDurationCost !== 0 &&
                comercialDetails.totalEventCost !== 0 &&
                // comercialDetails.waiterBarServiceTime !== 0 &&
                // comercialDetails.waiterBarExtraHourCost !== 0 &&
                // Validation of payment details
                paymentDetails.paymentsAmount !== 0 &&
                paymentDetails.prepaymentPercentage !== 0 &&
                paymentDetails.depositAmount !== 0 &&
                // Validation of payments list
                paymentsList.length !== 0
            ) {
                //Validation of customersAmount
                const amount = comercialDetails.adultsAmount + comercialDetails.childrenAmount + comercialDetails.teensAmount
                if (comercialDetails.totalGuests < amount || comercialDetails.totalGuests > amount) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "La cantidad de invitados es distinta a la indicada en la cotización",
                        type: 'error'
                    })
                } else if ((comercialDetails.teensAmount > 0 && comercialDetails.teenExtraCost === 0) || (comercialDetails.childrenAmount > 0 && comercialDetails.childrenExtraCost === 0)) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Existen campos obligatorios sin llenar",
                        type: 'error'
                    })
                } /* else if ((comercialDetails.waiterBarServiceTime > 0 && comercialDetails.waiterBarExtraHourCost === 0)) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Existen campos obligatorios sin llenar",
                        type: 'error'
                    })
                } */ else if (paymentsList.some(el => (el.paymentDueDate === null || !moment(el.paymentDueDate, 'YYYY-MM-DD').isValid()))) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Existen pagos sin fecha limite",
                        type: 'error'
                    })
                } else if(validatePaymentsQty(paymentsList, comercialDetails.totalEventCost)) {
                    setOpenAlert(true);
                    setDataAlert({
                        message: "Los pagos no coinciden con el costo total del evento",
                        type: 'error'
                    })
                } else {
                    // console.log("Contract Details: ", contractDetails)
                    // console.log("Comercial Details: ", comercialDetails)
                    // console.log("Payment Details: ", paymentDetails)
                    // console.log("Payments List: ", paymentsList)

                    paymentsList.forEach((payment) => { delete payment.paymentId })
                    paymentsList.forEach((payment) => { delete payment.paymentNumber })

                    // console.log("Payments List: ", paymentsList)

                    setTextLoader('Actualizando contrato')
                    setOpenLoader(true)

                    const response = await ContractService.UpdateContract(contractDetails, comercialDetails, paymentDetails, paymentsList, id)

                    if (response.success) {
                        setOpenAlert(true)
                        setDataAlert({
                            message: 'Contrato creado exitosamente',
                            type: 'success'
                        })

                        navigate(`/contratos/detalles/${id}`);
                    } else {
                        setOpenAlert(true)
                        setDataAlert({
                            message: response.message,
                            type: 'error'
                        })
                    }
                }
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                })
            }

            setOpenLoader(false)
            setTextLoader('')
        }
    }

    const validatePaymentsQty = (payments, totalEventCost) => {
        let paymentsQty = 0

        for (var i in payments) {
            paymentsQty += payments[i].paymentAmount
            console.log(payments[i].paymentAmount )
        }

        // console.log(paymentsQty)
        // console.log(totalEventCost)

        return paymentsQty !== totalEventCost
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    };

    const validateRFC = (rfc) => {
        const regex = /^([A-ZÑ]{3,4})?(?:-)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))?(?:-)?([A-Z\d]{3})?$/

        const validateRegex = rfc.match(regex)

        return validateRegex
    }

    /* Variables to Alerts */
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    //Variable to Create Contract
    const [customers, setCustomers] = useState([])
    const [eventTypes, setEventTypes] = useState([])

    //Variable for Contract Details
    const [contractDetails, setContractDetails] = useState({
        customer: null,
        eventDate: '',
        quotation: null,
        customerName: '',
        rfc: '',
        address: '',
        eventPlace: '',
    })

    //Variable for Comercial Details
    const [comercialDetails, setComercialDetails] = useState({
        totalGuests: 0,
        adultsAmount: 0,
        adultExtraCost: 0,
        childrenAmount: 0,
        childrenExtraCost: 0,
        teensAmount: 0,
        teenExtraCost: 0,
        eventDuration: 0,
        extraDurationCost: 0,
        totalEventCost: 0,
        waiterBarServiceTime: 0,
        waiterBarExtraHourCost: 0,
        includeIVA: false
    })

    //Variable for Payment Details
    const [paymentDetails, setPaymentDetails] = useState({
        paymentsAmount: 1,
        prepaymentPercentage: 100.0,
        prepaymentAmount: 0,
        depositAmount: 0,
    })

    //Variable for Payments
    const [paymentsList, setPaymentsList] = useState([])


    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Nuevo contrato'

        const getDataUpdateContract = async () => {
            const response = await ContractService.GetContractById(id)

            if (response.success) {
                //console.log(response)
                const data = response.data

                setContractDetails({
                    ...contractDetails,
                    customer: data.contract?.Customer,
                    eventDate: data.contract?.creationDate,
                    quotation: data.contract?.Quotations,
                    customerName: data.contract?.customerName,
                    rfc: data.contract?.RFC,
                    address: data.contract?.address,
                    eventPlace: data.contract?.eventPlace,
                })

                const totalPrice = data.contract?.totalPrice !== data.contract?.Quotations?.quotationPrice ? data.contract?.Quotations?.quotationPrice : data.contract?.totalPrice
                setComercialDetails({
                    ...comercialDetails,
                    totalGuests: data.guestQuantity,
                    adultsAmount: data.contract?.adultsAmount,
                    adultExtraCost: data.contract?.adultExtraCost,
                    childrenAmount: data.contract?.childrenAmount,
                    childrenExtraCost: data.contract?.childrenExtraCost,
                    teensAmount: data.contract?.teensAmount,
                    teenExtraCost: data.contract?.teensExtraCost,
                    eventDuration: data.contract?.eventDuration,
                    extraDurationCost: data.contract?.hourExtraCost,
                    waiterBarServiceTime: data.contract?.waiterBarServiceTime,
                    waiterBarExtraHourCost: data.contract?.waiterBarExtraHourCost,
                    totalEventCost: totalPrice,
                    includeIVA: data.contract?.IVA
                })

                const prepaymentAmount = (totalPrice * (data.contract?.advancePayments / 100)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                setPaymentDetails({
                    ...paymentDetails,
                    paymentsAmount: data.contract?.quantityPayments,
                    prepaymentPercentage: data.contract?.advancePayments,
                    prepaymentAmount: prepaymentAmount.replaceAll(",", ""),
                    depositAmount: data.contract?.depositPayments,
                })

                setPaymentsList(
                    data.payments
                )
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        if (id) {
            document.title = 'ELISA By Inova Evento | Actualizar cotización'

            setOpenLoader(true)
            setTitle('Actualizar Contrato')
            setButtonEnd('Actualizar Contrato')

            getDataUpdateContract()
        }
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <NavBar />

            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>{title}</Typography>
                {/* {
                    quotationCode.quotationCode === ''
                        ? <Typography variant="h5" align="center"></Typography>
                        : <Typography variant="h5" align="center">Cotización { quotationCode.quotationCode }</Typography>
                } */}
            </Container>

            <Container maxWidth='xl'>
                <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} style={{ marginBottom: '45px', marginTop: '45px' }} alternativeLabel>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {/* Content of stepper */}
                    <React.Fragment>
                        {activeStep !== steps.length - 1
                            ? <NewContractStepOne
                                customers={customers}
                                setCustomers={setCustomers}
                                comercialDetails={comercialDetails}
                                setComercialDetails={setComercialDetails}
                                eventTypes={eventTypes}
                                setEventTypes={setEventTypes}
                                contractDetails={contractDetails}
                                setContractDetails={setContractDetails}
                                id={id}
                                openAlert={openAlert}
                                setOpenAlert={setOpenAlert}
                                dataAlert={dataAlert}
                                setDataAlert={setDataAlert}
                                openLoader={openLoader}
                                setOpenLoader={setOpenLoader}
                                textLoader={textLoader}
                                setTextLoader={setTextLoader}
                                paymentDetails={paymentDetails}
                                setPaymentDetails={setPaymentDetails}
                            />
                            : <NewContractStepTwo
                                comercialDetails={comercialDetails}
                                setComercialDetails={setComercialDetails}
                                paymentDetails={paymentDetails}
                                setPaymentDetails={setPaymentDetails}
                                paymentsList={paymentsList}
                                setPaymentsList={setPaymentsList}
                                id={id}
                                openAlert={openAlert}
                                setOpenAlert={setOpenAlert}
                                dataAlert={dataAlert}
                                setDataAlert={setDataAlert}
                                openLoader={openLoader}
                                setOpenLoader={setOpenLoader}
                                textLoader={textLoader}
                                setTextLoader={setTextLoader}
                            />
                        }

                        <Box sx={{ display: "flex", flexDirection: "row", py: 4 }}>
                            <Button
                                color="inherit"
                                variant="contained"
                                size="small"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Regresar
                            </Button>

                            <Box sx={{ flex: "1 1 auto" }} />

                            <Button
                                variant="contained"
                                style={theme.buttonNew}
                                size="small"
                                onClick={!id ? handleNext : handleNextUpdate}
                            >
                                {activeStep === steps.length - 1 ? buttonEnd : "Siguiente"}
                            </Button>
                        </Box>
                    </React.Fragment>
                </Box>
            </Container>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
        </ThemeProvider>
    )
}

export default NewContract