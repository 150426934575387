import React, { useEffect, useState } from "react";

//Material UI imports
import {
    CssBaseline,
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    Container,
    TableBody,
    IconButton,
    Stack,
    Button,
    InputLabel,
    Input,
    InputAdornment,
    FormControl,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//Assets imports
//import SendIconRoute from "../assets/icons/Send.png";
import ViewIconRoute from "../assets/icons/View.png";
//import UpdateIconRoute from "../assets/icons/Update.png";

//CSS imports
import "./../assets/css/fonts.css"

//Components imports
import NavBar from "./../components/NavBarComponent/NavBarComponent.jsx"
import { Link, useNavigate } from "react-router-dom";

//Services import
import * as ItemsService from '../api/items'

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    tableTitle: {
        paddingTop: 30,
        paddingBottom: 30,
    },

    tableHeader: {
        color: "white",
        backgroundColor: "#2A2438",
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 30,
        width: 30,
    },

    containerButtonNew: {
        textAlign: "right",
        paddingTop: 100,
        paddingBottom: 30,
    },

    buttonNew: {
        backgroundColor: "#5C5470",
    }
});

const Items = () => {
    let navigate = useNavigate();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    /* Function of table */
    const [dataItems, setDataItems] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    //VARIABLE TO HANDLE SEARCH
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Items'

        const loadItems = async () => {
            const response = await ItemsService.GetItems("")
            if (response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                // Falta implementar alert que indique error
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        setOpenLoader(true)
        loadItems()
    }, [])

    const openDetails = async (id) => {
        navigate(`/items/actualizar/${id}`)
    }

    const handleSearch = async (value) => {
        //console.log("VALUE: ", value)
        setSearchQuery(value)

        if(value === ''){
            const response = await ItemsService.GetItems("")

            if(response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        } else {
            const response = await ItemsService.GetItems(value)

            if(response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        }
    }


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container style={theme.containerButtonNew}>
                <Link
                    to="/items/nuevo"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <Button variant="contained" style={theme.buttonNew} startIcon={<AddCircleOutlineIcon />}>Nuevo</Button>
                </Link>
            </Container>
            <Container style={{ textAlign: "right", paddingBottom: 15, }}>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="searchInput" color="independece">Búsqueda</InputLabel>
                    <Input
                        id="searchInput"
                        value={searchQuery}
                        onChange={( (e) => handleSearch(e.target.value) )}
                        color="independece"
                        endAdornment={
                            <InputAdornment position="end"> <SearchIcon  /> </InputAdornment>
                        }
                    />
                </FormControl>
            </Container>
            <Container>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow style={theme.tableHeader}>
                                <TableCell style={theme.tableHeader}>ID Item</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Nombre</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Descripción</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Costo</TableCell>
                                {/* <TableCell align="left" style={theme.tableHeader}>Descuento</TableCell> */}
                                {/* <TableCell align="left" style={theme.tableHeader}>Disponibles</TableCell> */}
                                <TableCell align="left" style={theme.tableHeader}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {data.itemId}
                                    </TableCell>
                                    <TableCell>
                                        {data.name}
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: "break-spaces" }}>
                                        {data.description}
                                    </TableCell>
                                    <TableCell>
                                        ${Number(data.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </TableCell>
                                    {/* <TableCell>
                                        {data.disccount}%
                                    </TableCell> */}
                                    {/* <TableCell>
                                        {Number(data.quantityAvailable).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                    </TableCell> */}
                                    <TableCell>
                                        <Stack direction="row" spacing={2} >
                                            <IconButton>
                                                <img
                                                    alt="View Icon"
                                                    src={ViewIconRoute}
                                                    style={theme.avatarActions}
                                                    onClick={() => openDetails(data.itemId)}
                                                />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    labelRowsPerPage="Registros por página"
                    count={dataItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value)
                        setPage(0);
                    }}
                />

                <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
                <LoaderScreen open={openLoader} text={textLoader} />
            </Container>
        </ThemeProvider>
    );
}

export default Items;