import React, { useEffect } from "react";

import * as Auth from "../../api/auth";

//Material UI imports
import { AppBar, Toolbar, Typography, Grid, Avatar, Container, Box, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import { createTheme } from '@mui/material/styles';

//Assets imports
import LogoInova from "./../../assets/imgs/InovaEvento_Black.png";
import userIconRoute from "../../assets/icons/User.png"

//CSS imports
import "./../../assets/css/fonts.css"

import { Link, useNavigate } from "react-router-dom";

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    toolbar: {
        minHeight: 64,
        alignItems: 'center',
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "#DBD8E3"
    },

    logoIE: {
        height: 125,
        marginLeft: 2
    },

    userInfo: {
        color: "black",
        marginRight: 1,
        fontSize: 17
    },

    userLastLogin: {
        color: "gray",
        marginRight: 1,
        fontSize: 14
    },

    avatarIcon: {
        marginLeft: 2
    }
});

const NavBar = () => {

    let navigate = useNavigate();

    const [anchorUser, setAnchorUser] = React.useState(null);

    const user =  JSON.parse(localStorage.getItem('user'));

    const handleOpenUserMenu = (event) => {
        setAnchorUser(event.currentTarget);
    };

    const handleCloseUserMenu = (event) => {
        setAnchorUser(null);
    }

    const logout = async (event) => {
        await Auth.Logout();
        navigate("/");
    }

    useEffect(() => {
        const verifySession = async () => {
            const response = await Auth.getMe()
            //console.log("navbar: ", response)

            if(!response.success) {
                localStorage.clear()
                navigate('/')
            } else {
                const exist = localStorage.getItem('user')

                if(!exist) {
                    const user = {
                        name: response.data.name,
                        lastLogin: moment(response.data.lastLogin).format("YYYY-MM-DD")
                    }
            
                    localStorage.setItem('user', JSON.stringify(user))
                }
            }
        }

        //verifySession()
    }, [])

    return (
        <AppBar position="fixed" elevation={0} style={theme.toolbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters style={{ maxHeight: '76px' }}>
                    <Link
                        to="/home"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <img
                            src={LogoInova}
                            alt="Logo Inova Evento"
                            style={theme.logoIE}
                        />
                    </Link>
                    <Grid container item edge="end">
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    align="right"
                                    noWrap
                                    style={theme.userInfo}
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', sm: 'block', md: 'block' },
                                        flexGrow: 1,
                                    }}
                                >
                                    {user?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    align="right"
                                    style={theme.userLastLogin}
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', sm: 'block', md: 'block' },
                                        flexGrow: 1,
                                    }}
                                >
                                    Último ingreso: {moment(user?.lastLogin).format('DD/MM/YYYY')}
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Opciones">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar
                                    src={userIconRoute}
                                    sx={{ width: 65, height: 65 }}
                                    alt="User's profile picture"
                                    style={theme.avatarIcon}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-settings"
                            anchorEl={anchorUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {/* <MenuItem key="profile" onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">Perfil</Typography>
                            </MenuItem> */}
                            <MenuItem key="sign_out" onClick={handleCloseUserMenu}>
                                <Typography textAlign="center" onClick={logout}>Cerrar sesión</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;