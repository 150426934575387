import React, { useEffect, useState } from "react"

//Material UI imports
import { 
    Typography,  
    Container, 
    Stack, 
    Box, 
    TextField,
    Autocomplete
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'

//CSS imports
import "./../../assets/css/fonts.css"
import "./../../assets/css/styles.css"

//Services import
import * as CustomerService from '../../api/customer'
import * as EventTypesService from '../../api/events'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 30,
        paddingBottom: 30,
        fontWeight: 800
    },
});

const NewQuotation = ({ 
    customers, 
    setCustomers, 
    eventTypes, 
    setEventTypes, 
    eventDetails, 
    setEventDetails, 
    id,
    openAlert, 
    setOpenAlert, 
    dataAlert, 
    setDataAlert, 
    openLoader, 
    setOpenLoader, 
    textLoader, 
    setTextLoader 
}) => {
    // Get customers by query
    const handleCustomerSearch = async (event, value, reason) => {
        //console.log('entro', value, event?.target)
        if (value) {
            //console.log(value)
            const response = await CustomerService.GetCustomers(value)
            if(response.success) {
                setCustomers(response.data)
            } else {
                //alert para indicar error
            }
        }
    }
    const handleOnChangeCustomerSearch = async (event, value, reason) => {
        //console.log("handle", value)
        setEventDetails({
            ...eventDetails,
            customer: value
        })
    };
    const defaultPropsCustomers = {
        options: customers,
        getOptionLabel: (option) => (option.name + ' ' + option.lastName + ' - ' + option.email),
    };

    // Get eventTypes by query
    const handleOnChangeEventTypeSearch = (event, value, reason) => {
        //console.log("handle", value)
        setEventDetails({
            ...eventDetails,
            eventType: value
        })
    };
    const defaultPropsEventType = {
        options: eventTypes,
        getOptionLabel: (option) => option.description,
    };

    useEffect(() => {
        const getEventTypes = async () => {
            const response = await EventTypesService.GetEventTypes()

            if(response.success) {
                setEventTypes(response.data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        const setCustomer = async () => {
            await handleCustomerSearch(null, eventDetails?.customer?.email, null)
        }

        if(!id) {
            setOpenLoader(true)
            getEventTypes()
        } else {
            setOpenLoader(true)
            getEventTypes()
            setCustomer()
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container >
                <Box sx={{ maxWidth: {xs: '100%', sm: '50%', md: '50%'} }}>
                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                        <Typography variant="h6">Cliente<span className="text-danger">*</span></Typography>
                        <Autocomplete
                            id="clientSearch"
                            style={{ margin: 0 }}
                            fullWidth
                            {...defaultPropsCustomers}
                            noOptionsText={"Sin resultados"}
                            onInputChange={handleCustomerSearch}
                            onChange={handleOnChangeCustomerSearch}
                            value={eventDetails?.customer ? eventDetails.customer : null}
                            renderInput={(params) => (
                                <TextField {...params} margin="normal" />
                            )}
                        />
                    </Stack>
                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                        <Typography variant="h6">Fecha del evento<span className="text-danger">*</span></Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="eventDate"
                            name="eventDate"
                            size="small"
                            color="independece"
                            type="date"
                            InputProps={{inputProps: { min: "0000-01-01", max: "9999-12-31"} }}
                            value={ eventDetails.eventDate }
                            onChange={(e) => {
                                setEventDetails({
                                    ...eventDetails,
                                    eventDate: e.target.value
                                })
                            }}
                        />
                    </Stack>
                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                        <Typography variant="h6">Tipo de evento<span className="text-danger">*</span></Typography>
                        <Autocomplete
                            id="eventTypeSearch"
                            style={{ margin: 0 }}
                            fullWidth
                            {...defaultPropsEventType}
                            noOptionsText={"Sin resultados"}
                            onChange={handleOnChangeEventTypeSearch}
                            value={eventDetails?.eventType ? eventDetails.eventType : null}
                            renderInput={(params) => (
                                <TextField {...params} margin="normal" />
                            )}
                        />
                    </Stack>
                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                        <Typography variant="h6">Cantidad de invitados<span className="text-danger">*</span></Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="guestQutantity"
                            name="guestQutantity"
                            size="small"
                            color="independece"
                            type="number"
                            value={ eventDetails.guestQuantity }
                            onChange={(e) => {
                                setEventDetails({
                                    ...eventDetails,
                                    guestQuantity: e.target.value
                                })
                            }}
                        />
                    </Stack>
                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                        <Typography variant="h6">Lugar del evento<span className="text-danger">*</span></Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="eventPlace"
                            name="EventPlace"
                            size="small"
                            color="independece"
                            type="text"
                            value={ eventDetails.eventPlace }
                            onChange={(e) => {
                                setEventDetails({
                                    ...eventDetails,
                                    eventPlace: e.target.value
                                })
                            }}
                        />
                    </Stack>
                    {/* <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                        <Typography variant="h6">Detalles del evento<span className="text-danger">*</span></Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="eventDetails"
                            name="eventDetails"
                            size="small"
                            color="independece"
                            multiline
                            rows={4}
                            value={ eventDetails.eventDetails }
                            onChange={(e) => {
                                setEventDetails({
                                    ...eventDetails,
                                    eventDetails: e.target.value
                                })
                            }}
                        />
                    </Stack> */}
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default NewQuotation;