import React, { useState, useEffect } from "react";

import * as Auth from "../api/auth"

//Material UI imports
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Alerts from '../components/alerts'

//React Hook Form imports
import { useForm } from "react-hook-form";

//Assets imports
import LogoInovaELISA from "../assets/imgs/ELISA.png";

//CSS imports
import "./../assets/css/fonts.css"

import { useNavigate } from 'react-router-dom';

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

//To add a new color, write its information in the pallete section
const theme = createTheme({
  palette: {
    independece: {
      main: '#5C5470',
      contrastText: '#FFFFFF',
    },
  },

  typography: {
    fontFamily: [
      "Montserrat-Regular"
    ].join(",")
  }
});

const Login = () => {
  let navigate = useNavigate();

  // Variables to Alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [dataAlert, setDataAlert] = useState({
    message: '',
    type: ''
  })
  const changeStateAlert = () => {
    setOpenAlert(!openAlert)
  }

  const emailField = React.useRef(null);
  const passwordField = React.useRef(null);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    //console.log(data);
    const response = await Auth.Login(data);

    if (response.success) {
      emailField.current.value = ""
      passwordField.current.value = ""

      const user = {
        name: response.data.name,
        lastLogin: response.data.lastLogin
      }

      localStorage.setItem('user', JSON.stringify(user))

      // Add token cookie that expires in 24 hours
      const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
      document.cookie = `jwtToken=${response.data.authorizationToken}; expires=${expires}; path=/; Secure`;

      // Add token refresh cookie that expires in 5 days
      const expiresRefresh = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString();
      document.cookie = `jwtRefresh=${response.data.refreshToken}; expires=${expiresRefresh}; path=/; Secure`;

      navigate("/home");

      //console.log("SUCCESS")

    } else {
      setOpenAlert(true);
      setDataAlert({
        message: response.message,
        type: 'error'
      })
      //console.log(response)
    }
  }

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.title = 'ELISA By Inova Evento | Login'

    const verifySession = async () => {
      const response = await Auth.getMe()
      console.log("index: ", response)

      if(response.success) {
        const user = {
          name: response.data.name,
          lastLogin: moment(response.data.lastLogin).format("YYYY-MM-DD")
        }

        localStorage.setItem('user', JSON.stringify(user))

        navigate("/home")
      }
    }

    //verifySession()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item xs={8} justify="center" alignItems="center">
            <img
              src={LogoInovaELISA}
              alt="Logo Inova Evento"
              style={{ width: "35vh" }}
            />
          </Grid>

          <Typography component="h1" variant="h4">
            Iniciar sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              color="independece"
              inputRef={emailField}
              {...register("email", {
                required: "Correo electrónico requerido.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Dirección de correo electrónico inválida.",
                },
              })}
              error={!!errors?.email}
              helperText={errors?.email ? errors.email.message : null}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Contraseña"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              size="small"
              color="independece"
              inputRef={passwordField}
              {...register("password", {
                required: "Contraseña requerida."
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errors?.password}
              helperText={errors?.password ? errors.password.message : null}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                backgroundColor: "#5C5470"
              }}
            >
              Acceder
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="/recuperar-contrasena" variant="body2" style={{ color: "#352F44" }} underline="hover">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>

        <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
      </Container>
    </ThemeProvider>
  );
};

export default Login;
