import { API } from '../config'
import fetch from "node-fetch"
import errorHandler from "../components/errorHandler"

//const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//LOGIN
export const Login = async (data) => {
    try {
        const body = {
            email: data.email,
            password: data.password
        }
        //console.log(body)
        const response = await fetch (`${API.BASEURL}/auth/login`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })

        const jsonResponse = await response.json()
        
        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500,"Error de conexión, favor de intentar más tarde.")
    }
}

//LOGOUT
export const Logout = async () => {
    document.cookie = `jwtToken=; expires=${Date.now()}; path=/; Secure`;
    document.cookie = `jwtRefresh=; expires=${Date.now()}; path=/; Secure`;

    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = "jwtToken=; expires=" + date.toUTCString() + "; path=/; Secure";
    document.cookie = "jwtRefresh=; expires=" + date.toUTCString() + "; path=/; Secure";

    localStorage.clear();
    //console.log('logged out')
}

//ME
export const getMe = async () => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]
    
    try {
        const response = await fetch (`${API.BASEURL}/auth/me`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })

        const jsonResponse = await response.json()
        
        if (jsonResponse.success) {           
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//ME
export const resetPassword = async (data, token) => {
    
    try {
        const body = {
            tempPassword: data.passwordTemp,
            newPassword: data.password
        }
        //console.log(body)
        const response = await fetch (`${API.BASEURL}/resetPassword/${token}`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })

        const jsonResponse = await response.json()
        
        if (jsonResponse.success) {           
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}