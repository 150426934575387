import { API } from '../config'
import errorHandler from "../components/errorHandler"

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//SEARCH CUSTOMERS BY QUERY
export const GetCustomers = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            query: data
        }

        const response = await fetch (`${API.BASEURL}/customers/getCustomers`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//GET CUSTOMERS BY QUERY
export const GetCustomerById = async (id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/customers/getCustomerById/${id}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//GET ALL CUSTOMERS
export const GetAllCustomers = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    let body = {
        query: data
    }

    try {
        const response = await fetch (`${API.BASEURL}/customers/getCustomers`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Create CUSTOMER
export const CreateCustomer = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            name: data.name,
            lastName: data.lastName,
            email: data.email,
            mobile: data.mobile
        }

        const response = await fetch (`${API.BASEURL}/customers/createCustomer`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Update CUSTOMER
export const UpdateCustomer = async (data, id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            name: data.name,
            lastName: data.lastName,
            email: data.email,
            mobile: data.mobile
        }

        const response = await fetch (`${API.BASEURL}/customers/updateCustomer/${id}`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//DELETE CUSTOMER
export const DeleteCustomer = async (customerId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/customers/deleteCustomer/${customerId}`, {
            method: "DELETE",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}