import React, { useState, useEffect } from "react";

import * as Auth from "../api/auth"

//Material UI imports
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Alerts from '../components/alerts'

//React Hook Form imports
import { useForm } from "react-hook-form";

//Assets imports
import LogoInovaELISA from "../assets/imgs/ELISA.png";

//CSS imports
import "./../assets/css/fonts.css"

import { useNavigate, useSearchParams } from 'react-router-dom';

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

//To add a new color, write its information in the pallete section
const theme = createTheme({
  palette: {
    independece: {
      main: '#5C5470',
      contrastText: '#FFFFFF',
    },
  },

  typography: {
    fontFamily: [
      "Montserrat-Regular"
    ].join(",")
  }
});

const VerifyUser = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  // Variables to Alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [dataAlert, setDataAlert] = useState({
    message: '',
    type: ''
  })
  const changeStateAlert = () => {
    setOpenAlert(!openAlert)
  }

  const passwordField = React.useRef(null);
  const passwordFieldVerify = React.useRef(null);
  const passwordFieldTemp = React.useRef(null);

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const onSubmit = async (data) => {
    console.log(data, token);
  }

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showPasswordVerify, setShowPasswordVerify] = useState(false)
  const handleClickShowPasswordVerify = () => {
    setShowPasswordVerify(!showPasswordVerify);
  };

  const [showPasswordTemp, setShowPasswordTemp] = useState(false)
  const handleClickShowPasswordTemp = () => {
    setShowPasswordTemp(!showPasswordTemp);
  };

  useEffect(() => {
    document.title = 'ELISA By Inova Evento | Cambiar contraseña'
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item xs={8} justify="center" alignItems="center">
            <img
              src={LogoInovaELISA}
              alt="Logo Inova Evento"
              style={{ width: "35vh" }}
            />
          </Grid>

          <Typography component="h1" variant="h4">
            Ingresa tu contraseña
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              name="passwordTemp"
              label="Contraseña Temporal"
              type={showPasswordTemp ? 'text' : 'password'}
              id="passwordTemp"
              autoComplete="current-password"
              size="small"
              color="independece"
              inputRef={passwordFieldTemp}
              {...register("passwordTemp", {
                required: "Contraseña temporal requerida."
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordTemp}
                    >
                      {showPasswordTemp ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errors?.passwordTemp}
              helperText={errors?.passwordTemp ? errors.passwordTemp.message : null}
            />

            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Contraseña"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              size="small"
              color="independece"
              inputRef={passwordField}
              {...register("password", {
                required: "Contraseña requerida."
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errors?.password}
              helperText={errors?.password ? errors.password.message : null}
            />
            <TextField
              margin="normal"
              fullWidth
              name="passwordVerify"
              label="Verificar Contraseña"
              type={showPasswordVerify ? 'text' : 'password'}
              id="passwordVerify"
              autoComplete="current-password"
              size="small"
              color="independece"
              inputRef={passwordFieldVerify}
              {...register("passwordVerify", {
                required: "Contraseña requerida.",
                validate: value => value === getValues("password") || "Las contraseñas no coinciden."
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordVerify}
                    >
                      {showPasswordVerify ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errors?.passwordVerify}
              helperText={errors?.passwordVerify ? errors.passwordVerify.message : null}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                backgroundColor: "#5C5470"
              }}
            >
              Aceptar
            </Button>
          </Box>
        </Box>

        <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
      </Container>
    </ThemeProvider>
  );
};

export default VerifyUser;
