import React, { useState } from "react";

//Material UI imports
import {
    Snackbar,
    Alert,
    AlertTitle,
} from "@mui/material";

//CSS imports
import "../assets/css/fonts.css"
import "../assets/css/styles.css"

const Alerts = ({changeState, open, data}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        changeState()
    };

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} className='alerts'>
          <Alert onClose={handleClose} severity={data.type !== '' ? data.type : 'success'} variant='filled' sx={{ width: '100%' }}>
            { data.message }
          </Alert>
        </Snackbar>
    )
}

export default Alerts