import { API } from '../config'
import errorHandler from "../components/errorHandler"

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//Create CONTRACT
export const CreateContract = async (contractDetails, comercialDetails, paymentDetails, paymentsList) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            customerId: contractDetails.customer.customerId,
            creationDate: contractDetails.eventDate,
            eventId: contractDetails.quotation.Events.eventId,
            customerName: contractDetails.customerName,
            RFC: contractDetails.rfc,
            address: contractDetails.address,
            eventPlace: contractDetails.eventPlace,
            quotationId: contractDetails.quotation.quotationId,
            adultsAmount: comercialDetails.adultsAmount,
            teensAmount: comercialDetails.teensAmount,
            childrenAmount: comercialDetails.childrenAmount,
            adultExtraCost: comercialDetails.adultExtraCost,
            childrenExtraCost: comercialDetails.childrenExtraCost,
            teensExtraCost: comercialDetails.teenExtraCost,
            eventDuration: comercialDetails.eventDuration,
            hourExtraCost: comercialDetails.extraDurationCost,
            totalPrice: comercialDetails.totalEventCost,
            waiterBarServiceTime: comercialDetails.waiterBarServiceTime,
            waiterBarExtraHourCost: comercialDetails.waiterBarExtraHourCost,
            quantityPayments: paymentDetails.paymentsAmount,
            advancePayments: paymentDetails.prepaymentPercentage,
            depositPayments: paymentDetails.depositAmount,
            payments: paymentsList,
            includeIVA: comercialDetails.includeIVA
        }

        //console.log(body)

        const response = await fetch (`${API.BASEURL}/contracts/newContract`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//GET ALL CONTRACTS
export const GetAllContracts = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    let body = {
        query: data
    }

    try {
        const response = await fetch (`${API.BASEURL}/contracts/getAllContracts`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//GET CONTRACT BY ID
export const GetContractById = async (contractId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/contracts/getContract/${contractId}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Get PDF contract
export const GetContractPDF = async (id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/contracts/file/contract/${id}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Send PDF contract
export const SendContractPDF = async (contractId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/contracts/sendMail/${contractId}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//UPDATE CONTRACT
export const UpdateContract = async (contractDetails, comercialDetails, paymentDetails, paymentsList, contractId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            customerName: contractDetails.customerName,
            creationDate: contractDetails.eventDate,
            RFC: contractDetails.rfc,
            address: contractDetails.address,
            eventPlace: contractDetails.eventPlace,
            adultsAmount: comercialDetails.adultsAmount,
            teensAmount: comercialDetails.teensAmount,
            childrenAmount: comercialDetails.childrenAmount,
            personExtraCost: comercialDetails.extraCustomerCost,
            eventDuration: comercialDetails.eventDuration,
            adultExtraCost: comercialDetails.adultExtraCost,
            teensExtraCost: comercialDetails.teenExtraCost,
            childrenExtraCost: comercialDetails.childrenExtraCost,
            hourExtraCost: comercialDetails.extraDurationCost,
            totalPrice: comercialDetails.totalEventCost,
            waiterBarServiceTime: comercialDetails.waiterBarServiceTime,
            waiterBarExtraHourCost: comercialDetails.waiterBarExtraHourCost,
            quantityPayments: paymentDetails.paymentsAmount,
            advancePayments: paymentDetails.prepaymentPercentage,
            depositPayments: paymentDetails.depositAmount,
            payments: paymentsList,
            includeIVA: comercialDetails.includeIVA
        }

        //console.log(body)

        const response = await fetch (`${API.BASEURL}/contracts/updateContract/${contractId}`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//DELETE CONTRACT
export const DeleteContract = async (contractId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/contracts/deleteContract/${contractId}`, {
            method: "DELETE",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}