import React, { useEffect, useState } from "react";

//Material UI imports
import {
    CssBaseline,
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    Container,
    TableBody,
    IconButton,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputLabel,
    Input,
    InputAdornment,
    FormControl,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import Alerts from '../components/alerts.jsx'
import LoaderScreen from "../components/loaderScreen.jsx"

//Assets imports
import DeleteIconRoute from '../assets/icons/Delete.png'
import ViewIconRoute from "../assets/icons/View.png";
import ReactivateIcon from "../assets/icons/Update.png"
import ChangePasswordIcon from "../assets/icons/changePassword.png"

//CSS imports
import "./../assets/css/fonts.css"

//Components imports
import NavBar from "../components/NavBarComponent/NavBarComponent.jsx"
import { Link, useNavigate } from "react-router-dom";

//Services import
import * as UserServices from '../api/users.jsx'

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    tableTitle: {
        paddingTop: 30,
        paddingBottom: 30,
    },

    tableHeader: {
        color: "white",
        backgroundColor: "#2A2438",
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 30,
        width: 30,
    },

    containerButtonNew: {
        textAlign: "right",
        paddingTop: 100,
        paddingBottom: 30,
    },

    buttonNew: {
        backgroundColor: "#5C5470",
    }
});

const Users = () => {
    let navigate = useNavigate();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Variables to Confirm delete user
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [dataConfirmation, setDataConfirmation] = useState({
        message: '',
        id: ''
    })
    const changeStateConfirmation = () => {
        setOpenConfirmation(!openConfirmation)
    }

    // Varibales to Confirm reactivation of user
    const [openConfirmationReactivate, setOpenConfirmationReactivate] = useState(false);
    const [dataConfirmationReactivate, setDataConfirmationReactivate] = useState({
        message: '',
        id: ''
    })
    const changeStateConfirmationReactivate = () => {
        setOpenConfirmationReactivate(!openConfirmationReactivate)
    }

    // Varibales to reset password of user
    const [openConfirmationResetPassword, setOpenConfirmationResetPassword] = useState(false);
    const [dataConfirmationResetPassword, setDataConfirmationResetPassword] = useState({
        open: false,
        id: '',
        password: '',
        message: ''
    })
    const changeStateConfirmationResetPassword = () => {
        setOpenConfirmationResetPassword(!openConfirmationResetPassword)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    /* Function of table */
    const [dataItems, setDataItems] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    //VARIABLE TO HANDLE SEARCH
    const [searchQuery, setSearchQuery] = useState('')

    const loadItems = async () => {
        const response = await UserServices.GetUsers("")
        if (response.success) {
            let data = response.data
            setDataItems(data)
        } else {
            // Falta implementar alert que indique error
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
    }

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Usuarios'

        setOpenLoader(true)
        loadItems()
    }, [])

    const openDetails = async (id) => {
        navigate(`/usuarios/actualizar/${id}`)
    }

    const deleteUser = async () => {
        const id = dataConfirmation.id
        const response = await UserServices.DeleteUser(id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: "Se eliminó correctamente al usuario",
                type: 'success'
            })

            setDataConfirmation({
                message: '',
                id: ''
            })
            changeStateConfirmation()

            setOpenLoader(true)
            loadItems()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const reactivateUser = async () => {
        const id = dataConfirmationReactivate.id
        const response = await UserServices.ReactivateUser(id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: "Se reactivo correctamente al usuario",
                type: 'success'
            })

            setDataConfirmationReactivate({
                message: '',
                id: ''
            })
            changeStateConfirmationReactivate()

            setOpenLoader(true)
            loadItems()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const handleSearch = async (value) => {
        //console.log("VALUE: ", value)
        setSearchQuery(value)

        if(value === ''){
            const response = await UserServices.GetUsers("")

            if(response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        } else {
            const response = await UserServices.GetUsers(value)

            if(response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        }
    }

    const resetPassword = async () => {
        setOpenLoader(true)
        const id = dataConfirmationResetPassword.id
        const response = await UserServices.resetPasswordUser(id)

        if(response.success) {
            console.log(response)
            
            setDataConfirmationResetPassword({
                ...dataConfirmationResetPassword,
                password: response.data.password,
                open: true
            })
            setOpenConfirmationResetPassword(false)
        } else {
            setOpenAlert(true)
            setDataAlert({
                message: response.message,
                type: 'error'
            })
            //console.log(response)
        }
        setOpenLoader(false)
    }

    const copyPassword = async () => {
        try {
            await navigator.clipboard.writeText(dataConfirmationResetPassword.password);
            setDataAlert({
                message: 'Contraseña copiada al portapapeles' ,
                type: 'info' 
            })
            setOpenAlert(true)
        } catch (err) {
            setDataAlert({
                message: 'Error al copiar la contraseña al portapapeles' ,
                type: 'error' 
            })
            setOpenAlert(true)
        }
    }

    const hidePassword = (password) => {
        return '*'.repeat(password.length)
    }


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container style={theme.containerButtonNew}>
                <Link
                    to="/usuarios/nuevo"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <Button variant="contained" style={theme.buttonNew} startIcon={<AddCircleOutlineIcon />}>Nuevo</Button>
                </Link>
            </Container>
            <Container style={{ textAlign: "right", paddingBottom: 15, }}>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="searchInput" color="independece">Búsqueda</InputLabel>
                    <Input
                        id="searchInput"
                        value={searchQuery}
                        onChange={( (e) => handleSearch(e.target.value) )}
                        color="independece"
                        endAdornment={
                            <InputAdornment position="end"> <SearchIcon  /> </InputAdornment>
                        }
                    />
                </FormControl>
            </Container>
            <Container>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow style={theme.tableHeader}>
                                <TableCell style={theme.tableHeader}>ID Usuario</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Nombre</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Apellidos</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Correo</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Rol</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Estatus</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {data.userId}
                                    </TableCell>
                                    <TableCell>
                                        {data.name}
                                    </TableCell>
                                    <TableCell>
                                        {data.lastName}
                                    </TableCell>
                                    <TableCell>
                                        {data.email}
                                    </TableCell>
                                    <TableCell>
                                        {data.role}
                                    </TableCell>
                                    <TableCell>
                                        {data.status}
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={2} >
                                            <IconButton
                                                onClick={() => openDetails(data.userId)}
                                            >
                                                <img
                                                    alt="View Icon"
                                                    src={ViewIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setDataConfirmationResetPassword({
                                                        id: data.userId,
                                                        message: (data.userId + ' y nombre ' + data.name + ' ' + data.lastName),
                                                        password: '',
                                                        open: false
                                                    })

                                                    setOpenConfirmationResetPassword(true)
                                                }}
                                            >
                                                <img
                                                    alt="Password Icon"
                                                    src={ChangePasswordIcon}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                            {data.status !== 'Deshabilitado' && <IconButton
                                                onClick={() => {
                                                    setDataConfirmation({
                                                        message: (data.userId + ' y nombre ' + data.name + ' ' + data.lastName),
                                                        id: data.userId
                                                    })

                                                    setOpenConfirmation(true)
                                                }}
                                            >
                                                <img
                                                    alt="Delete Icon"
                                                    src={DeleteIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>}
                                            {data.status === 'Deshabilitado' && <IconButton
                                                onClick={() => {
                                                    setDataConfirmationReactivate({
                                                        message: (data.userId + ' y nombre ' + data.name + ' ' + data.lastName),
                                                        id: data.userId
                                                    })

                                                    setOpenConfirmationReactivate(true)
                                                }}
                                            >
                                                <img
                                                    alt="Reactivate Icon"
                                                    src={ReactivateIcon}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>}
                                        </Stack>
                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    labelRowsPerPage="Registros por página"
                    count={dataItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value)
                        setPage(0);
                    }}
                />

                <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
                <LoaderScreen open={openLoader} text={textLoader} />
            </Container>
            <Dialog
                open={openConfirmation}
                onClose={changeStateConfirmation}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Eliminar usuario
                </DialogTitle>
                <DialogContent>
                    ¿Esta seguro de eliminar al usuario con <strong>ID {dataConfirmation.message}</strong>?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={changeStateConfirmation}>Cancelar</Button>
                    <Button onClick={deleteUser}>Aceptar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmationReactivate}
                onClose={changeStateConfirmationReactivate}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Reactivar usuario
                </DialogTitle>
                <DialogContent>
                    ¿Esta seguro de reactivar al usuario con <strong>ID {dataConfirmationReactivate.message}</strong>?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={changeStateConfirmationReactivate}>Cancelar</Button>
                    <Button onClick={reactivateUser}>Aceptar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmationResetPassword}
                onClose={changeStateConfirmationResetPassword}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Nueva contraseña
                </DialogTitle>
                <DialogContent>
                    ¿Esta seguro de generar una nueva contraseña para el usuario con <strong>ID {dataConfirmationResetPassword.message}</strong>?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={changeStateConfirmationResetPassword}>Cancelar</Button>
                    <Button onClick={resetPassword}>Aceptar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dataConfirmationResetPassword.open}
                onClose={() => {
                    setDataConfirmationResetPassword({
                        open: false,
                        id: '',
                        message: '',
                        password: ''
                    })
                }}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Nueva contraseña
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ textAlign: 'center' }}>Nueva contraseña generada</p>
                        <span>Contraseña</span>
                        <span>{hidePassword(dataConfirmationResetPassword.password)}</span>
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button 
                        onClick={() => {
                            setDataConfirmationResetPassword({
                                open: false,
                                id: '',
                                message: '',
                                password: ''
                            })
                        }}
                    >
                        Cerrar
                    </Button>
                    <Button onClick={copyPassword}>Copiar Contraseña</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default Users;