import React, { useEffect, useState } from "react"

//Material UI imports
import {
    Typography,
    Container,
    Stack,
    Box,
    TextField,
    Autocomplete,
    InputAdornment,
    CssBaseline,
    Grid
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'

//CSS imports
import "./../../assets/css/fonts.css"
import "./../../assets/css/styles.css"

//Services import
import * as CustomerServices from '../../api/customer'
import * as EventTypesService from '../../api/events'
import * as QuotationService from '../../api/quotations'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 30,
        paddingBottom: 30,
        fontWeight: 800
    },
});

const NewContractStepOne = ({
    customers,
    setCustomers,
    comercialDetails,
    setComercialDetails,
    eventTypes,
    setEventTypes,
    contractDetails,
    setContractDetails,
    id,
    openAlert,
    setOpenAlert,
    dataAlert,
    setDataAlert,
    openLoader,
    setOpenLoader,
    textLoader,
    setTextLoader,
    paymentDetails,
    setPaymentDetails,
}) => {



    useEffect(() => {
        const getEventTypes = async () => {
            const response = await EventTypesService.GetEventTypes()

            if (response.success) {
                setEventTypes(response.data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        // const setCustomer = async () => {
        //     await handleCustomerSearch(null, eventDetails?.customer?.email, null)
        // }

        if (!id) {
            setOpenLoader(true)
            getEventTypes()
        } else {
            setOpenLoader(true)
            getEventTypes()
            //setCustomer()
        }
    }, [])

    const defaultPropsCustomers = {
        options: customers,
        getOptionLabel: (option) => (option.name + ' ' + option.lastName + ' - ' + option.email),
    };

    // Get customers by query
    const handleCustomerSearch = async (event, value, reason) => {
        //console.log('entro', value, event?.target)
        if (value) {
            //console.log(value)
            const response = await CustomerServices.GetCustomers(value)
            if (response.success) {
                setCustomers(response.data)
            } else {
                //alert para indicar error
            }
        }
    }

    const getCustomerEvents = async (customerId) => {
        const response = await QuotationService.GetCustomerQuotation(customerId)
        //console.log(response)

        if (response.success) {
            setEventTypes(response.data)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
    }

    const handleOnChangeCustomerSearch = async (event, value, reason) => {
        //console.log("handle", value)
        setContractDetails({
            ...contractDetails,
            customer: value
        })

        getCustomerEvents(value.customerId)
    };

    const defaultPropsCustomerQuotations = {
        options: eventTypes,
        getOptionLabel: (option) => option.quotationCode,
    };

    // Get eventTypes by query
    const handleOnChangeCustomerQuotationsSearch = (event, value, reason) => {
        //console.log("handle", value.Events?.eventPlace)
        setContractDetails({
            ...contractDetails,
            quotation: value,
            eventPlace: value.Events?.eventPlace
        })

        setComercialDetails({
            ...comercialDetails,
            totalGuests: value.Events?.guestQuantity,
            totalEventCost: value.quotationPrice
        })

        setPaymentDetails({
            ...paymentDetails,
            prepaymentAmount: value.quotationPrice
        })
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container>
                <Typography variant="h4" align="center" style={theme.newQuotationTitle}>Detalles del contrato</Typography>
            </Container>
            <Container maxWidth='xl'>
                {/* EVENT DETAILS FIRST PART */}
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Container>
                                <Box>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">Cliente<span className="text-danger">*</span></Typography>
                                        <Autocomplete
                                            disabled={contractDetails.customer && id}
                                            id="clientSearch"
                                            style={{ margin: 0 }}
                                            fullWidth
                                            {...defaultPropsCustomers}
                                            noOptionsText={"Sin resultados"}
                                            onInputChange={handleCustomerSearch}
                                            onChange={handleOnChangeCustomerSearch}
                                            value={contractDetails.customer ? contractDetails.customer : null}
                                            renderInput={(params) => (
                                                <TextField {...params} margin="normal" />
                                            )}
                                        />
                                    </Stack>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">Cotización<span className="text-danger">*</span></Typography>
                                        <Autocomplete
                                            disabled={contractDetails.quotation && id}
                                            id="customerQuotationsSearch"
                                            style={{ margin: 0 }}
                                            fullWidth
                                            {...defaultPropsCustomerQuotations}
                                            noOptionsText={"Sin resultados"}
                                            onChange={handleOnChangeCustomerQuotationsSearch}
                                            value={contractDetails.quotation ? contractDetails.quotation : null}
                                            renderInput={(params) => (
                                                <TextField {...params} margin="normal" />
                                            )}
                                        />
                                    </Stack>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">Fecha final del evento<span className="text-danger">*</span></Typography>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="eventDate"
                                            name="eventDate"
                                            size="small"
                                            color="independece"
                                            type="date"
                                            InputProps={{ inputProps: { min: "0000-01-01", max: "9999-12-31" } }}
                                            value={contractDetails.eventDate ? moment(contractDetails.eventDate).utc().format("YYYY-MM-DD") : null}
                                            onChange={(e) => {
                                                setContractDetails({
                                                    ...contractDetails,
                                                    eventDate: e.target.value
                                                })
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">Lugar del evento<span className="text-danger">*</span></Typography>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="eventPlace"
                                            name="EventPlace"
                                            size="small"
                                            color="independece"
                                            type="text"
                                            value={contractDetails.eventPlace ? contractDetails.eventPlace : null}
                                            onChange={(e) => {
                                                setContractDetails({
                                                    ...contractDetails,
                                                    eventPlace: e.target.value
                                                })
                                            }}
                                        />
                                    </Stack>
                                </Box>
                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Container>
                                <Box>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">Nombre en el Contrato<span className="text-danger">*</span></Typography>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="contractName"
                                            name="ContractName"
                                            size="small"
                                            color="independece"
                                            type="text"
                                            value={contractDetails.customerName ? contractDetails.customerName : null}
                                            onChange={(e) => {
                                                setContractDetails({
                                                    ...contractDetails,
                                                    customerName: e.target.value
                                                })
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">RFC<span className="text-danger">*</span></Typography>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="rfc"
                                            name="rfc"
                                            size="small"
                                            color="independece"
                                            type="text"
                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                            value={contractDetails.rfc ? contractDetails.rfc : null}
                                            onChange={(e) => {
                                                setContractDetails({
                                                    ...contractDetails,
                                                    rfc: e.target.value.toUpperCase()
                                                })
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                                        <Typography variant="h6">Dirección<span className="text-danger">*</span></Typography>
                                        <TextField
                                            value={contractDetails.address ? contractDetails.address : null}
                                            margin="normal"
                                            fullWidth
                                            id="address"
                                            name="Address"
                                            size="small"
                                            color="independece"
                                            multiline
                                            rows={4}
                                            onInput={(e) => {
                                                e.target.value = (e.target.value).toString().slice(0, 2500)
                                            }}
                                            onChange={(e) => {
                                                setContractDetails({
                                                    ...contractDetails,
                                                    address: e.target.value
                                                })
                                            }}
                                        />
                                    </Stack>
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </ThemeProvider>
    );
}

export default NewContractStepOne;