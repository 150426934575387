import React, { useEffect, useState, forwardRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import PhoneNumber from 'libphonenumber-js';

//Material UI imports
import { Typography, CssBaseline, Container, Stack, Button, Box, TextField, FormHelperText, FormControl } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Alerts from '../components/alerts.jsx'
import LoaderScreen from "../components/loaderScreen.jsx"

//React Hook Form imports
import { useForm, Controller } from "react-hook-form";

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Components imports
import NavBar from "../components/NavBarComponent/NavBarComponent.jsx"

//Services import
import * as CustomerServices from '../api/customer.jsx'

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 30,
        fontWeight: 800
    },
});

const PhoneInputWithRef = forwardRef((props, ref) => (
    <PhoneInput {...props} innerRef={ref} />
));

const NewClient = (props) => {
    let navigate = useNavigate();
    const { id } = useParams();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    const [action, setAction] = useState('Nuevo Cliente')
    const [item, setItem] = useState([])
    
    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Nuevo cliente'
        
        const getUser = async () => {
            const response = await CustomerServices.GetCustomerById(id)

            if(response.success) {
                //console.log(response)
                setAction('Actualizar Cliente')
                
                //setItem(response.data)
                reset({
                    ...response.data
                })
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        if( id && id !== undefined && id !== null) {
            document.title = 'ELISA By Inova Evento | Actualizar cliente'

            setOpenLoader(true)
            getUser()
        }
    }, [])

    const { register, handleSubmit, control, formState: { errors }, reset, trigger } = useForm();
    const onSubmit = async (data) => {
        //console.log(data)

        if(action === 'Nuevo Cliente') {
            setOpenLoader(true)

            const response = await CustomerServices.CreateCustomer(data)
            //console.log(response)

            if(response.success) {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Cliente agregado exitosamente',
                    type: 'success'
                })

                navigate("/clientes");
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        } else {
            setOpenLoader(true)

            const response = await CustomerServices.UpdateCustomer(data, id)
            //console.log(response)

            if(response.success) {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Cliente actualizado correctamente',
                    type: 'success'
                })

                navigate("/clientes");
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }
    }

    const [selectedCountry, setSelectedCountry] = useState('mx');
    const [phoneNumber, setPhoneNumber] = useState(undefined)
    const handleChange = (value, country) => {
        const number = value.slice(country.dialCode.length)
        setSelectedCountry(country.countryCode);
        setPhoneNumber(number)
    };

    const validatePhoneNumber = () => {
        try {
            const phoneNumberValidation = PhoneNumber(phoneNumber, selectedCountry.toUpperCase());
            if (phoneNumberValidation && (!phoneNumberValidation.isValid() || !phoneNumberValidation.isPossible())) {
                return "Número telefónico inválido.";
            }
            return true;
        } catch (error) {
            return "Número telefónico inválido.";
        }
    };

    useEffect(() => {
        trigger("mobile");
    }, [phoneNumber, selectedCountry])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>{ action }</Typography>
            </Container>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                <Container >
                    <Box sx={{ maxWidth: {xs: '100%', sm: '50%', md: '50%'} }}>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Nombre<span className="text-danger">*</span></Typography>
                            <TextField
                                //value={item.name}
                                
                                defaultValue={ item.name }
                                margin="normal"
                                fullWidth
                                id="name"
                                name="name"
                                size="small"
                                color="independece"
                                type="text"
                                {...register("name", {
                                    required: "El nombre del cliente es obligatorio."
                                })}
                                error={!!errors?.name}
                                helperText={errors?.name ? errors.name.message : null}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Apellidos<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.lastName}
                                margin="normal"
                                fullWidth
                                id="lastName"
                                name="lastName"
                                size="small"
                                color="independece"
                                type="text"
                                {...register("lastName", {
                                    required: "Los apellidos del cliente son obligatorios."
                                })}
                                error={!!errors?.lastName}
                                helperText={errors?.lastName ? errors.lastName.message : null}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Correo<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.email}
                                margin="normal"
                                fullWidth
                                id="email"
                                name="email"
                                size="small"
                                color="independece"
                                type="text"
                                {...register("email", {
                                    required: "El correo electrónico del cliente es obligatorio.",
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Dirección de correo electrónico inválida.",
                                    },
                                })}
                                error={!!errors?.email}
                                helperText={errors?.email ? errors.email.message : null}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Teléfono<span className="text-danger">*</span></Typography>
                            <Controller
                                name="mobile"
                                control={control}
                                rules={{
                                    required: "El número telefónico es obligatorio.",
                                    validate: validatePhoneNumber,
                                }}
                                render={({ field: { ref, ...field } }) => (
                                    <FormControl fullWidth error={!!errors.mobile} margin="normal">
                                        <PhoneInputWithRef
                                            {...field}
                                            country={selectedCountry}
                                            onlyCountries={['mx', 'us']}
                                            value={field.value}
                                            onChange={(value, country, e, formattedValue) => {
                                                handleChange(value, country)
                                                field.onChange(value)
                                            }}
                                            countryCodeEditable={false}
                                            inputStyle={{ width: '100%', borderColor: errors.mobile ? 'red' : '' }}
                                            specialLabel=''
                                            ref={ref}
                                        />
                                        {errors.mobile && (
                                            <FormHelperText>
                                                {errors.mobile.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </Stack>
                    </Box>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="right"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                backgroundColor: "#5C5470",
                                
                            }}
                        >
                            Guardar
                        </Button>
                    </Stack>
                </Container>
            </Box>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
        </ThemeProvider>
    );
}

export default NewClient;