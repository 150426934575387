import { API } from '../config'
import errorHandler from "../components/errorHandler"

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//GET ITEMS
export const GetItems = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    let body = {
        query: data
    }

    try {
        const response = await fetch (`${API.BASEURL}/items/getItems`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

// GET ITEMS by ID
export const GetItemsById = async (id) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/items/getItemById/${id}`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

/* Create ITEMS 
@params
    * data (Array)
        * name (string)
        * description (string)
        * price (number)
        * quantityAvailable (number)
        * disccount (number)
*/
export const CreateItems = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            name: data.name,
            description: data.description,
            price: data.price,
            discount: data.discount,
            quantityAvailable: data.quantityAvailable
        }

        const response = await fetch (`${API.BASEURL}/items/createItems`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

/* Update ITEMS
@params
    * data (Array)
        * itemId (number)
        * name (string)
        * description (string)
        * price (number)
        * quantityAvailable (number)
        * disccount (number)
*/
export const UpdateItems = async (data, itemId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        let body = {
            name: data.name,
            description: data.description,
            price: data.price,
            discount: data.discount,
            quantityAvailable: data.quantityAvailable
        }

        const response = await fetch (`${API.BASEURL}/items/updateItems/${itemId}`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Delete ITEMS
export const DeleteItems = async (itemId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/items/deleteItems/${itemId}`, {
            method: "DELETE",
            body: [],
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//ReactivateItems
export const ReactivateItems = async (itemId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]
    
    try {
        const response = await fetch (`${API.BASEURL}/items/reactivateItems/${itemId}`, {
            method: "PATCH",
            body: [],
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}