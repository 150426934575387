import React, { useEffect, useState } from "react";

//Material UI imports
import {
    CssBaseline,
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    Container,
    TableBody,
    IconButton,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    InputLabel,
    Input,
    InputAdornment,
    FormControl,
} from '@mui/material';
import { esES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//Assets imports
import DeleteIconRoute from '../assets/icons/Delete.png'
import ViewIconRoute from "../assets/icons/View.png";
import UpdateIconRoute from "../assets/icons/Update.png";

//CSS imports
import "./../assets/css/fonts.css"

//Components imports
import NavBar from "./../components/NavBarComponent/NavBarComponent.jsx"
import { Link, useNavigate } from "react-router-dom";

//Services import
import * as ContractsService from '../api/contracts'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    tableTitle: {
        paddingTop: 30,
        paddingBottom: 30,
    },

    tableHeader: {
        color: "white",
        backgroundColor: "#2A2438",
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 30,
        width: 30,
    },

    containerButtonNew: {
        textAlign: "right",
        paddingTop: 100,
        paddingBottom: 30,
    },

    buttonNew: {
        backgroundColor: "#5C5470",
    }
},
    esES
);

const Contracts = () => {
    let navigate = useNavigate();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Variables to Confirm
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [dataConfirmation, setDataConfirmation] = useState({
        message: '',
        id: ''
    })
    const changeStateConfirmation = () => {
        setOpenConfirmation(!openConfirmation)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    /* Function of table */
    const [dataItems, setDataItems] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    //VARIABLE TO HANDLE SEARCH
    const [searchQuery, setSearchQuery] = useState('')

    const loadItems = async () => {
        const response = await ContractsService.GetAllContracts("")
        if (response.success) {
            let data = response.data
            setDataItems(data)
        } else {
            // Falta implementar alert que indique error
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
    }

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Contratos'

        setOpenLoader(true)
        loadItems()
    }, [])

    const openDetails = async (id) => {
        navigate(`/contratos/detalles/${id}`)
    }

    const deleteContract = async () => {
        const id = dataConfirmation.id

        const response = await ContractsService.DeleteContract(id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: "Se eliminó correctamente el contrato",
                type: 'success'
            })

            setDataConfirmation({
                message: '',
                id: ''
            })
            changeStateConfirmation()

            setOpenLoader(true)
            loadItems()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const updateContract = async (id) => {
        navigate(`/contratos/actualizar/${id}`)
    }

    const handleSearch = async (value) => {
        //console.log("VALUE: ", value)
        setSearchQuery(value)

        if (value === '') {
            const response = await ContractsService.GetAllContracts("")

            if (response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        } else {
            const response = await ContractsService.GetAllContracts(value)

            if (response.success) {
                let data = response.data
                setDataItems(data)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container style={theme.containerButtonNew}>
                <Link
                    to="/contratos/nuevo"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <Button variant="contained" style={theme.buttonNew} startIcon={<AddCircleOutlineIcon />}>Nuevo</Button>
                </Link>
            </Container>
            <Container>
                <Typography variant="h5" style={{ textAlign: "left" }}>Contratos</Typography>
                <Container style={{ textAlign: "right", paddingBottom: 15}}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                        <InputLabel htmlFor="searchInput" color="independece">Búsqueda</InputLabel>
                        <Input
                            id="searchInput"
                            value={searchQuery}
                            onChange={((e) => handleSearch(e.target.value))}
                            color="independece"
                            endAdornment={
                                <InputAdornment position="end"> <SearchIcon /> </InputAdornment>
                            }
                        />
                    </FormControl>
                </Container>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow style={theme.tableHeader}>
                                <TableCell style={theme.tableHeader}>Código</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Cliente</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Fecha</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Estatus</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {data.Contracts?.contractCode}
                                    </TableCell>
                                    <TableCell>
                                        {data.Contracts?.Customer?.name} {data.Contracts?.Customer?.lastName}
                                    </TableCell>
                                    <TableCell>
                                        {moment(data.Contracts?.creationDate).utc().format('dddd[,] DD [de] MMMM [de] YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {data.TagDescriptionsContracts?.tag}
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={2} >
                                            <IconButton
                                                onClick={() => openDetails(data.Contracts?.contractId)}
                                            >
                                                <img
                                                    alt="View Icon"
                                                    src={ViewIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => updateContract(data.Contracts?.contractId)}
                                            >
                                                <img
                                                    alt="Update Icon"
                                                    src={UpdateIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setDataConfirmation({
                                                        message: (data.Contracts?.contractCode + ', con ID ' + data.Contracts?.contractId + ', del cliente ' + data.Contracts?.Customer?.name + ' ' + data.Contracts?.Customer?.lastName),
                                                        id: data.Contracts?.contractId
                                                    })

                                                    setOpenConfirmation(true)
                                                }}
                                            >
                                                <img
                                                    alt="Delete Icon"
                                                    src={DeleteIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}

                            {dataItems.length === 0 && (<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>No hay registros a mostrar</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    labelRowsPerPage="Registros por página"
                    count={dataItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value)
                        setPage(0);
                    }}
                />

            </Container>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
            <Dialog
                open={openConfirmation}
                onClose={changeStateConfirmation}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Eliminar contrato
                </DialogTitle>
                <DialogContent>
                    ¿Esta seguro de eliminar la información correspondiente al contrato {dataConfirmation.message}? <p><strong>ESTA ACCIÓN ES IRREVERSIBLE.</strong></p>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={changeStateConfirmation}>Cancelar</Button>
                    <Button onClick={deleteContract}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default Contracts;