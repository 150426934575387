import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

//Material UI imports
import {
    Typography,
    CssBaseline,
    Container,
    Stack,
    Button,
    Box,
    Grid,
    FormControl,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Assets imports


//Components imports
import NavBar from "../components/NavBarComponent/NavBarComponent.jsx"

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//Services imports
import * as ContractsService from './../api/contracts'
import * as ContractTagsService from './../api/contractTags'
import * as FollowupContractService from '../api/followupContracts'

// Import the main component PDF viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles PDF viewer
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { base64ToBlob } from 'base64toblob'
import { saveAs } from 'file-saver'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 30,
        fontWeight: 800
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 65,
        width: 65,
    },

    buttonAddPersonalizedItem: {
        backgroundColor: "#5C5470",
        align: "right",
        marginBottom: 10,
    }
});

const ContractDetails = () => {
    const { id } = useParams();
    let navigate = useNavigate();

    // Create new plugin instance PDF viewer
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            /* Remove sidebarTabs (Indicate what tabs show)
            @params (position) 
                * Bookmarks, 0
                * Thumbnails, 1
                * Attatchments, 2
            */
            defaultTabs[0]
        ]
    });

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    const [contract, setContract] = useState([])
    const [pdfContract, setPDFContract] = useState(null)

    const getContract = async () => {
        const response = await ContractsService.GetContractById(id)
        //console.log(response.data)

        if (response.success) {
            setContract(response.data)



            await getPDF()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
    }

    const getPDF = async () => {
        const response = await ContractsService.GetContractPDF(id)
        //console.log(response)

        if (response.success) {
            const blob = base64ToBlob(response.data, 'application/pdf');
            const url = await URL.createObjectURL(blob);

            setPDFContract(url)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'No se pudo cargar el documento',
                type: 'error'
            })
            //console.log(response)
        }
    }

    const getContractComments = async () => {
        const response = await FollowupContractService.GetContractComments(id)

        if (response.success) {
            const data = response.data
            const comments = data.reverse()

            setContractComments(comments)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const getContractTags = async () => {
        const response = await ContractTagsService.GetAllContractTags()

        if (response.success) {
            setContractStatus(response.data)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const getContractStatus = async (id) => {
        //console.log(statusSelected)

        const response = await ContractTagsService.GetTagFromContract(id)

        if (response.success) {
            const data = response.data

            if (data.length !== 0) setContractStatusSelected(data.tagId)
            else setContractStatusSelected('')
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Detalles de contrato'

        setOpenLoader(true)
        getContract()
        getContractComments()
        getContractTags()
        getContractStatus(id)
    }, [])

    const handleSendMail = async () => {
        setTextLoader('Enviando contrato')
        setOpenLoader(true)

        const response = await ContractsService.SendContractPDF(id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: 'Correo enviado de manera exitosa',
                type: 'success'
            })
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'Error al enviar correo, favor de intentar mas tarde.',
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
        setTextLoader('')
    }

    const handleDownloadContract = async () => {
        setTextLoader('Descargando el archivo')
        setOpenLoader(true)
        
        const response = await ContractsService.GetContractPDF(id)

        if (response.success) {
            //console.log(response)

            const blob = base64ToBlob(response.data, 'application/pdf');

            await saveAs(blob, contract.contract?.contractCode)

        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'Error al descargar PDF, favor de intentar mas tarde.',
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
        setTextLoader('')
    }

    /* Comments & status */
    const [contractStatus, setContractStatus] = useState([])
    const [contractStatusSelected, setContractStatusSelected] = useState('')

    const [contractComments, setContractComments] = useState([])
    const [newContractComment, setNewComment] = useState({
        comment: '',
        newContractComment: false
    })

    const handleNewContractComment = async () => {
        //console.log(newComent)

        if (newContractComment.comment !== '') {
            const data = {
                idClient: contract.contract.Customer.customerId,
                idContract: contract.contract.contractId,
                comment: newContractComment.comment
            }

            //console.log(data)

            const response = await FollowupContractService.AddCommentToContract(data)

            if (response.success) {
                await getContractComments()

                setNewComment({
                    comment: '',
                    newContractComment: false
                })
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'El comentario no puede estar vacio.',
                type: 'warning'
            })
        }
    }

    const handleUpdateStatus = async () => {
        //console.log('update status', statusSelected)

        setOpenLoader(true)

        const response = await ContractTagsService.UpdateContractStatus(contractStatusSelected, id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: 'Estatus del contrato actualizado correctamente',
                type: 'success'
            })
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }

        setOpenLoader(false)
    }

    const convertToTagName = (tagId) => {
        for (const i in contractStatus) {
            if (tagId === contractStatus[i].tagId) {

                return contractStatus[i].tag
            }
        }

        return 'Sin Estatus'
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>Detalles del contrato</Typography>
            </Container>
            <Container>
                <Typography variant="h5" sx={{ textAlign: { xs: 'center', sm: 'right', md: 'right' }, paddingTop: 5, paddingBottom: 5 }} >Contrato {contract.contract?.contractCode}</Typography>
            </Container>



            <Container maxWidth="xl" >
                <Grid container>
                    <Grid item xs={12} sm={12} md={4}>
                        <Container>
                            <Box>
                                <Container>
                                    <Grid>
                                        <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                                            <Typography variant="h6" style={{ fontWeight: 800 }}>Cliente:</Typography>
                                            <Typography variant="h6" style={{ paddingLeft: 48 }}>{contract.contract?.Customer?.name + ' ' + contract.contract?.Customer?.lastName}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                                            <Typography variant="h6" style={{ fontWeight: 800 }}>Fecha:</Typography>
                                            <Typography variant="h6" style={{ paddingLeft: 57 }}>{moment(contract.contract?.creationDate).utc().format('dddd DD [de] MMMM [de] YYYY')} </Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                                            <Typography variant="h6" style={{ fontWeight: 800 }}>Tipo Evento:</Typography>
                                            <Typography variant="h6" style={{ paddingLeft: 0 }}>{contract.event?.description}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid container spacing={4} sx={{ paddingBottom: 3, paddingTop: 3 }}>
                                        <Grid item xs={6} sm={6} md={8} lg={6} xl={6}>
                                            <Button
                                                startIcon={<DownloadIcon />}
                                                style={{ backgroundColor: '#DBD8E3', color: 'black', fontWeight: 800, height: '100%', width: '100%', fontSize: 11 }}
                                                onMouseDown={(e)=>e.preventDefault()} 
                                                onContextMenu={(e)=>e.preventDefault()}
                                                onClick={handleDownloadContract}
                                            >
                                                Descargar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={8} lg={6} xl={6}>
                                            <Button
                                                startIcon={<EmailIcon />}
                                                style={{ backgroundColor: '#DBD8E3', color: 'black', fontWeight: 800, height: '100%', width: '100%', fontSize: 11 }}
                                                onClick={handleSendMail}
                                            >
                                                Enviar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Container>
                            {pdfContract && <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                                <div className='webViewer' style={{ marginBottom: 30 }}>
                                    <Viewer
                                        fileUrl={pdfContract}
                                        plugins={[
                                            // Register plugins
                                            defaultLayoutPluginInstance,
                                        ]}
                                    />
                                </div>
                            </Worker>}
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            {/* Contract follwoup  */}
            <Container maxWidth="xl" sx={{ mb: 8 }}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Container maxWidth="xl">
                            <Box sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100%' } }}>
                                <Typography variant="h5" style={{ fontWeight: 800, color: '#000000' }}>Seguimiento a firma de contrato</Typography>
                            </Box>
                        </Container>

                        <Container maxWidth="lg">
                            <Box sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100%' } }}>

                                {/* Contract Status */}
                                <Typography variant="body1" style={{ fontWeight: 800, color: '#000000' }} sx={{ mt: 4 }}>Estatus:</Typography>
                                <Grid container sx={{ pb: 4 }}>
                                    <Grid item container xs={12} sm={12} md={8} justify="center" alignItems="center" direction="row" columnSpacing={4}>
                                        <Grid item xs={12} sm={9} md={9}>
                                            <FormControl fullWidth size="small">
                                                <Select
                                                    fullWidth
                                                    displayEmpty
                                                    onChange={(e) => setContractStatusSelected(e.target.value)}
                                                    value=""
                                                    renderValue={
                                                        contractStatusSelected !== '' ? () => <div>{convertToTagName(contractStatusSelected)}</div> : () => <div style={{ color: '#aaaaaa' }}>sin status!</div>
                                                    }
                                                >
                                                    {
                                                        contractStatus.map((element) => {
                                                            return (
                                                                <MenuItem key={element.tagId} value={element.tagId}>{element.tag}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3} sx={{ textAlign: { xs: 'end', sm: 'end', md: 'start' } }}>
                                            <Button
                                                variant="contained"
                                                sx={{ mt: 1, mb: 2 }}
                                                style={{
                                                    backgroundColor: "#000000",
                                                    color: '#FFFFFF'
                                                }}
                                                onClick={handleUpdateStatus}
                                            >
                                                Actualizar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {contractComments.length !== 0 && <Typography variant="body1" style={{ fontWeight: 800, color: '#000000' }} sx={{ mt: 2 }}>Comentarios:</Typography>}
                                {/* Add new comment */}
                                {newContractComment.newContractComment && <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body2" sx={{ mt: 2 }}><b style={{ color: '#000000' }}>Nuevo comentario</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                onInput={(e) => {
                                                    e.target.value = (e.target.value).toString().slice(0, 1000)
                                                }}
                                                onChange={(e) => {
                                                    setNewComment({
                                                        ...newContractComment,
                                                        comment: e.target.value
                                                    })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} style={{ textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#000000",
                                                    color: '#FFFFFF'
                                                }}
                                                sx={{ mr: 2 }}
                                                onClick={handleNewContractComment}
                                            >
                                                Guardar
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#5d5b5b",
                                                    color: '#FFFFFF'
                                                }}
                                                sx={{ ml: 2 }}
                                                onClick={() => {
                                                    setNewComment({
                                                        comment: '',
                                                        newContractComment: false
                                                    })
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>}

                                {/* Comments */}
                                <Grid container spacing={1}>
                                    {!newContractComment.newContractComment && <Grid item xs={12} sm={12} md={4} sx={{ display: { md: 'none' } }}>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#000000",
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => {
                                                setNewComment({
                                                    ...newContractComment,
                                                    newContractComment: true
                                                })
                                            }}
                                        >
                                            Comentar
                                        </Button>
                                    </Grid>}

                                    {
                                        contractComments.map((comment, index) => {
                                            return (
                                                index === 0
                                                    ? <>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Typography variant="body2" sx={{ mt: 2 }}><b style={{ color: '#000000' }}>{comment.Users?.name + ' ' + comment.Users?.lastName}</b> escribió el {moment(comment.commentDate).format('DD [de] MMMM [de] YYYY [a las] HH:mm')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8}>
                                                            <TextField
                                                                className='textAreaComments'
                                                                fullWidth
                                                                multiline
                                                                minRows={4}
                                                                disabled
                                                                value={comment.comment}
                                                            />
                                                        </Grid>

                                                        {!newContractComment.newContractComment && <Grid item xs={12} sm={12} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: "#000000",
                                                                    color: '#FFFFFF'
                                                                }}
                                                                onClick={() => {
                                                                    setNewComment({
                                                                        ...newContractComment,
                                                                        newContractComment: true
                                                                    })
                                                                }}
                                                            >
                                                                Comentar
                                                            </Button>
                                                        </Grid>}
                                                    </>
                                                    : <>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Typography variant="body2" sx={{ mt: 2 }}><b style={{ color: '#000000' }}>{comment.Users?.name + ' ' + comment.Users?.lastName}</b> escribio el {moment(comment.commentDate).format('DD [de] MMMM [de] YYYY [a las] HH:mm')}:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8}>
                                                            <TextField
                                                                className='textAreaComments'
                                                                fullWidth
                                                                multiline
                                                                minRows={4}
                                                                disabled
                                                                value={comment.comment}
                                                            />
                                                        </Grid>
                                                    </>
                                            )
                                        })
                                    }

                                    {!newContractComment.newContractComment && contractComments.length === 0 && <Grid item xs={12} sm={12} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#000000",
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => {
                                                setNewComment({
                                                    ...newContractComment,
                                                    newContractComment: true
                                                })
                                            }}
                                        >
                                            Comentar
                                        </Button>
                                    </Grid>}

                                </Grid>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
        </ThemeProvider>
    );
}

export default ContractDetails;