import React, { useEffect, useState } from "react";

//Material UI imports
import {
    Typography,
    CssBaseline,
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    Container,
    TableBody,
    IconButton,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    MenuItem,
    InputLabel,
    Input,
    InputAdornment,
    FormControl,
    TextField,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from '@mui/icons-material/CalendarMonth.js';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

//Assets imports
import SendIconRoute from "../assets/icons/Send.png";
import ViewIconRoute from "../assets/icons/View.png";
import UpdateIconRoute from "../assets/icons/Update.png";
import DeleteIconRoute from "../assets/icons/Delete.png";

//CSS imports
import "./../assets/css/fonts.css"

//Components imports
import NavBar from "./../components/NavBarComponent/NavBarComponent.jsx"
import { Link, useNavigate } from "react-router-dom";

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//Services import
import * as QuotationsService from '../api/quotations'
import * as EventsService from '../api/events'
import * as TagsService from './../api/tags'
import * as UsersService from './../api/users.jsx'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    tableTitle: {
        paddingTop: 30,
        paddingBottom: 30,
    },

    tableHeader: {
        color: "white",
        backgroundColor: "#2A2438",
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 30,
        width: 30,
    },

    containerButtonNew: {
        textAlign: "right",
        paddingTop: 100,
    },

    buttonNew: {
        backgroundColor: "#5C5470",
    }
});

const Quotations = () => {
    let navigate = useNavigate();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Variables to Confirm
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [dataConfirmation, setDataConfirmation] = useState({
        message: '',
        id: ''
    })
    const changeStateConfirmation = () => {
        setOpenConfirmation(!openConfirmation)
    }

    //VARIABLE TO HANDLE SEARCH
    const [searchQuery, setSearchQuery] = useState('')

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    // Variable to filters header
    const [status, setStatus] = useState([])
    const [events, setEvents] = useState([])
    const [filterValues, setFilterValues] = useState({ statusValue: '', eventValue: '', userValue: 0 });
    const [anchorElStatus, setAnchorElStatus] = useState(null)
    const [anchorElEvent, setAnchorElEvent] = useState(null);
    const openListStatus = Boolean(anchorElStatus)
    const openListEvent = Boolean(anchorElEvent)
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [users, setUsers] = useState([])
    const [anchorElUser, setAnchorElUser] = useState(null)
    const openListUsers = Boolean(anchorElUser)

    const handleClickListStatus = (event) => {
        setAnchorElStatus(event.currentTarget)
    }
    const handleClickListEvent = (event) => {
        setAnchorElEvent(event.currentTarget)
    }
    const handleClickListUsers = (event) => {
        setAnchorElUser(event.currentTarget)
    }
    const handleCloseList = () => {
        setAnchorElEvent(null)
        setAnchorElStatus(null)
        setAnchorElUser(null)
    }

    const handleStatusSelected = (event) => {
        const value = event.currentTarget.value;
        const statusValue = value === 0 
            ? 'Sin Estatus' 
            : value === -1
                ? ''
                : status.find((item) => item.tagId === value)?.tag
        setFilterValues((prevValues) => ({ ...prevValues, statusValue: statusValue }));
        handleCloseList()
    }

    const handleEventSelected = (event) => {
        const value = event.currentTarget.value;
        const eventValue = value === -1
            ? ''
            : events.find((item) => item.typeId === value)?.description
        setFilterValues((prevValues) => ({ ...prevValues, eventValue: eventValue }));
        handleCloseList()
    }

    const handleUserSelected = (event) => {
        const value = event.currentTarget.value
        const userValue = value === -1
            ? 0
            : value
        setFilterValues((prevValues) => ({ ...prevValues, userValue: userValue }))
        handleCloseList()
    }
    
    useEffect(() => {
        const getQuotationByFilters = async () => {
            const { statusValue, eventValue, userValue } = filterValues;

            const response = await QuotationsService.GetQuotations(searchQuery, eventValue, statusValue, userValue, selectedDate?.format('YYYY-MM-DD'))

            if (response.success) {
                //console.log(response.data)
    
                setDataNew(response.data.new)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
    
            setOpenLoader(false)
        }

        setOpenLoader(true)
        getQuotationByFilters()
    }, [filterValues, selectedDate])

    /* Function of table */
    const [dataNew, setDataNew] = useState([])
    const [dataApproved, setDataApproved] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    // Get data of quotations
    const getQuotations = async () => {
        const response = await QuotationsService.GetQuotations("")

        if (response.success) {
            //console.log(response.data)

            setDataNew(response.data.new)
            //setDataAproved(response.data.aproved)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }

        setOpenLoader(false)
    }

    const getTags = async () => {
        const response = await TagsService.GetAllTags()

        if (response.success) {
            const data = response.data
            const newData = []

            newData.push({
                tagId: 0,
                tag: "Sin Estatus"
            })
            newData.push(...data)
            newData.push({
                tagId: -1,
                tag: "Todas"
            })
            setStatus(newData)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }
    
    const getEvents = async () => {
        const response = await EventsService.GetEventTypes()

        if (response.success) {
            const data = response.data
            const newData = []

            newData.push(...data)
            newData.push({
                typeId: -1,
                description: "Todas",
                notes: "Todas",
                status: "Todas"
            })

            setEvents(newData)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const getUsersByQuotations = async () => {
        const response = await UsersService.getUsersByQuotations()

        if (response.success) {
            const data = response.data
            const newData = []

            newData.push(...data)
            newData.push({
                userId: -1,
                name: "Todos",
            })

            setUsers(newData)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Cotizaciones'

        setOpenLoader(true)
        getQuotations()
        getEvents()
        getTags()
        getUsersByQuotations()
    }, [])

    const updateQuotation = async (id) => {
        navigate(`/cotizaciones/actualizar/${id}`)
    }

    const deleteQuotation = async () => {
        const id = dataConfirmation.id
        const response = await QuotationsService.DeleteQuotation(id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: "Se elimino correctamente la cotización",
                type: 'success'
            })

            setDataConfirmation({
                message: '',
                id: ''
            })
            changeStateConfirmation()

            setOpenLoader(true)
            getQuotations()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const viewQuotation = async (id,status) => {
        if (status.toLowerCase() === 'borrador') {
            updateQuotation(id)
        } else {
            navigate(`/cotizaciones/detalles/${id}`);
        }
    }

    const handleSendMail = async (id) => {
        setTextLoader('Enviando cotización')
        setOpenLoader(true)

        const response = await QuotationsService.SendQuotationPDF(id)

        if (response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: 'Correo enviado de manera exitosa',
                type: 'success'
            })
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'Error al enviar correo, favor de intentar mas tarde.',
                type: 'error'
            })
            //console.log(response)
        }

        setOpenLoader(false)
        setTextLoader('')
    }

    const handleSearch = async (value) => {
        //console.log("VALUE: ", value)
        setSearchQuery(value)

        if (value === '') {
            const response = await QuotationsService.GetQuotations("")

            if (response.success) {
                let data = response.data
                setDataNew(data.new)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        } else {
            const response = await QuotationsService.GetQuotations(value)

            if (response.success) {
                let data = response.data
                setDataNew(data.new)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container style={theme.containerButtonNew}>
                <Link
                    to="/cotizaciones/nueva"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <Button variant="contained" style={theme.buttonNew} startIcon={<AddCircleOutlineIcon />}>Nueva</Button>
                </Link>
            </Container>

            {/* New Quotations Table */}
            <Container style={{ marginBottom: '25px' }}>
                <Typography variant="h5" style={{ textAlign: "left" }}>Lista de cotizaciones</Typography>
                <Container style={{ textAlign: "right", paddingBottom: 15 }}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                        <InputLabel htmlFor="searchInput" color="independece">Búsqueda</InputLabel>
                        <Input
                            id="searchInput"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            color="independece"
                            endAdornment={
                                <InputAdornment position="end"> <SearchIcon /> </InputAdornment>
                            }
                        />
                    </FormControl>
                </Container>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow style={theme.tableHeader}>
                                <TableCell style={theme.tableHeader}>ID Cotización</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Nombre del cliente</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>
                                    <Button
                                      style={{ color: '#FFFFFF', textTransform: 'none' }}
                                      onClick={handleClickListEvent}
                                      endIcon={openListEvent ? <ExpandLess /> : <ExpandMore />}
                                    >
                                        <p style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                                            Evento
                                            {(filterValues.eventValue !== '' || filterValues.eventValue !== 'Todas') && <span style={{ fontSize: 'x-small',  }}>{filterValues.eventValue}</span>}
                                        </p>
                                    </Button>
                                    <Menu
                                      anchorEl={anchorElEvent}
                                      open={openListEvent}
                                      onClose={handleCloseList}
                                    >
                                        {events.map((eventType, index) => {
                                            return (
                                              <MenuItem
                                                key={eventType.typeId}
                                                onClick={handleEventSelected}
                                                value={eventType.typeId}
                                              >
                                                  {eventType.description}
                                              </MenuItem>
                                            )
                                        })}
                                    </Menu>
                                </TableCell>
                                <TableCell align="left" style={theme.tableHeader}>
                                    <Button
                                      style={{ color: '#FFFFFF', textTransform: 'none' }}
                                      onClick={() => setOpenDatePicker(true)}
                                      endIcon={<CalendarIcon />}

                                    >
                                        <p style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                                            Fecha del Evento
                                            {selectedDate && <span style={{ fontSize: 'x-small',  }}>{selectedDate.format('DD/MM/YYYY')}</span>}
                                        </p>
                                    </Button>

                                    <div style={{ display: 'none'}}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <MobileDatePicker
                                                label={'"year", "month" and "day"'}
                                                views={['year', 'month', 'day']}
                                                open={openDatePicker}
                                                onAccept={(value) => setSelectedDate(value)}
                                                onClose={() => setOpenDatePicker(false)}
                                                slotProps={{
                                                    actionBar: {
                                                        actions: ['clear', 'cancel', "accept"]
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </TableCell>
                                <TableCell align="left" style={theme.tableHeader}>
                                    <Button
                                        style={{ color: '#FFFFFF', textTransform: 'none' }}
                                        onClick={handleClickListUsers}
                                        endIcon={openListUsers ? <ExpandLess /> : <ExpandMore />}
                                    >
                                        <p style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                                            Organizador
                                            {(
                                                filterValues.userValue !== 0 || 
                                                filterValues.userValue !== -1
                                            ) && 
                                            <span style={{ fontSize: 'x-small',  }}>
                                                {
                                                    users.find((user) => user.userId === filterValues.userValue)?.name
                                                }
                                            </span>}
                                        </p>
                                    </Button>
                                    <Menu
                                        anchorEl={anchorElUser}
                                        open={openListUsers}
                                        onClose={handleCloseList}
                                    >
                                        {users.map((user, index) => {
                                            return (
                                                <MenuItem
                                                    key={user.userId}
                                                    onClick={handleUserSelected}
                                                    value={user.userId}
                                                >
                                                    {user.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Menu>
                                </TableCell>
                                <TableCell align="left" style={theme.tableHeader}>
                                    <Button
                                        style={{ color: '#FFFFFF', textTransform: 'none' }}
                                        onClick={handleClickListStatus}
                                        endIcon={openListStatus ? <ExpandLess /> : <ExpandMore />}
                                    >
                                        <p style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                                            Estatus
                                            {(filterValues.statusValue !== '' || filterValues.statusValue !== 'Todas') && <span style={{ fontSize: 'x-small',  }}>{filterValues.statusValue}</span>}
                                        </p>
                                    </Button>
                                    <Menu
                                        anchorEl={anchorElStatus}
                                        open={openListStatus}
                                        onClose={handleCloseList}
                                    >
                                        {status.map((tag, index) => {
                                            return (
                                                <MenuItem
                                                    key={tag.tagId}
                                                    onClick={handleStatusSelected}
                                                    value={tag.tagId}
                                                >
                                                    {tag.tag}
                                                </MenuItem>
                                            )
                                        })}
                                    </Menu>
                                </TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataNew.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {data.quotationId}
                                    </TableCell>
                                    <TableCell>
                                        {data.clientName}
                                    </TableCell>
                                    <TableCell>
                                        {data.eventType}
                                    </TableCell>
                                    <TableCell>
                                        {moment(data.eventDate).utc().format('dddd DD [de] MMMM [de] YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {data.creator}
                                    </TableCell>
                                    <TableCell>
                                        {data.tag}
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={2} >
                                            {false && <IconButton
                                                onClick={() => handleSendMail(data.quotationId)}
                                            >
                                                <img
                                                    alt="Send Icon"
                                                    src={SendIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>}
                                            <IconButton
                                                onClick={() => viewQuotation(data.quotationId, data.tag)}
                                            >
                                                <img
                                                    alt="View Icon"
                                                    src={ViewIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => updateQuotation(data.quotationId)}
                                            >
                                                <img
                                                    alt="Update Icon"
                                                    src={UpdateIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setDataConfirmation({
                                                        message: (data.clientId + ',  del cliente ' + data.clientName + ' y evento ' + data.eventType),
                                                        id: data.quotationId
                                                    })

                                                    setOpenConfirmation(true)
                                                }}
                                            >
                                                <img
                                                    alt="Delete Icon"
                                                    src={DeleteIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}

                            {dataNew.length === 0 && (<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>No hay registros a mostrar</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>

                {dataNew.length !== 0 && (<TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    labelRowsPerPage="Registros por página"
                    count={dataNew.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value)
                        setPage(0);
                    }}
                />)}
            </Container>

            {/* Aprobed QuotationsTable */}
            {false && <Container style={{ marginBottom: '25px' }}>
                <Typography variant="h5" style={theme.tableTitle}>Aprobadas</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow style={theme.tableHeader}>
                                <TableCell style={theme.tableHeader}>ID Cliente</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Nombre del cliente</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Evento</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Fecha del Evento</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Organizador</TableCell>
                                <TableCell align="left" style={theme.tableHeader}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataApproved.map((data, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {data.clientId}
                                    </TableCell>
                                    <TableCell>
                                        {data.clientName}
                                    </TableCell>
                                    <TableCell>
                                        {data.eventType}
                                    </TableCell>
                                    <TableCell>
                                        {moment(data.eventDate).utc().format('dddd DD [de] MMMM [de] YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {data.creator}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => viewQuotation(data.quotationId, data.tag)}
                                        >
                                            <img
                                                alt="View Icon"
                                                src={ViewIconRoute}
                                                style={theme.avatarActions}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
                <LoaderScreen open={openLoader} text={textLoader} />
            </Container>}

            <Dialog
                open={openConfirmation}
                onClose={changeStateConfirmation}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Eliminar cotización
                </DialogTitle>
                <DialogContent>
                    ¿Esta seguro de eliminar cotización con ID {dataConfirmation.message} <strong>(se eliminara el contrato asociado a la cotización)</strong>, <p><strong>esta accion es irreversible</strong></p>?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={changeStateConfirmation}>Cancelar</Button>
                    <Button onClick={deleteQuotation}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default Quotations;