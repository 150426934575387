import { API } from '../config'
import errorHandler from "../components/errorHandler"

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

//GET EVENTTYPES
export const GetEventTypes = async () => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/eventType/getEventTypes`, {
            method: "GET",
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Create EVENTTYPE
export const CreateEventTypes = async (data) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/eventType/createEventType`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Update EVENTTYPE
export const UpdateEventTypes = async (data, eventTypeId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/eventType/updateEventType/${eventTypeId}`, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Delete EVENTTYPE
export const DaleteEventTypes = async (eventTypeId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/eventType/deleteEventType/${eventTypeId}`, {
            method: "PATCH",
            body: [],
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}

//Reactivate EVENTTYPE
export const ReactivateEventTypes = async (eventTypeId) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch (`${API.BASEURL}/eventType/reactivateEventType/${eventTypeId}`, {
            method: "PATCH",
            body: [],
            headers: {
                'Authorization': jwtToken,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }

    } catch (error) {
        return errorHandler(500, "Error de conexión, favor de intentar más tarde.")
    }
}