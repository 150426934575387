import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

//Material UI imports
import { 
    Typography, 
    CssBaseline, 
    Container, 
    Stack, 
    Button, 
    Box, 
    TableContainer, 
    TableRow, 
    TableCell, 
    Table, 
    TableBody, 
    Grid,
    FormControl,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateIcon from '@mui/icons-material/Update';

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Assets imports
import SendIconRoute from "./../assets/icons/Send.png"
import UpdateIconRoute from "./../assets/icons/Update.png"
import ViewIconRoute from "./../assets/icons/View.png"

//Components imports
import NavBar from "../components/NavBarComponent/NavBarComponent.jsx"

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//Services imports
import * as QuotationService from './../api/quotations'
import * as TagsService from './../api/tags'
import * as FollowupService from '../api/followup'

// Import the main component PDF viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles PDF viewer
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { base64ToBlob } from 'base64toblob'
import { saveAs } from 'file-saver'

import { API } from '../config'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 30,
        fontWeight: 800
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 65,
        width: 65,
    },

    buttonAddPersonalizedItem: {
        backgroundColor: "#5C5470",
        align: "right",
        marginBottom: 10,
    }
});

const QuotationDetails = () => {
    const { id } = useParams();
    let navigate = useNavigate();

    // Create new plugin instance PDF viewer
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            /* Remove sidebarTabs (Indicate what tabs show)
            @params (position) 
                * Bookmarks, 0
                * Thumbnails, 1
                * Attatchments, 2
            */
            defaultTabs[0]
        ]
    });

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    const [quotation, setQuotation] = useState([])
    const [itemsQuantity, setItemsQuantity] = useState({
        total: 0,
        active: 0,
        custom: 0
    })
    const [totalPrice, setTotalPrice] = useState('')
    const [pdfQuotation, setPDFQuotation] = useState(null)

    const getQuotation = async () => {
        const response = await QuotationService.GetQuotationDetails(id)
        //console.log(response)

        if(response.success) {
            setQuotation(response.data)

            let itemsActive = []
            let itemsCustom = []
            const items = response.data.items
            for(var i in items) {
                const item = items[i]

                switch(item.status) {
                    case 'active':
                        itemsActive.push(item)
                        break
                    case 'custom':
                        itemsCustom.push(item)
                        break
                    default:
                        break
                }
            }

            setItemsQuantity({
                total: items.length,
                active: itemsActive.length,
                custom: itemsCustom.length
            })

            let price = 0
            for(var i in items) {
                const item = items[i] 

                const disccount = (item.price * item.disccount) / 100
                //console.log(disccount, item.quantity, item.price, item.disccount)

                price += (item.quantity * (item.price - disccount))
                //console.log(price)
            }

            const priceFormatted = `$${Number(price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            setTotalPrice(priceFormatted)

            await getPDF()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            }) 
            //console.log(response)
        }

        setOpenLoader(false)
    }

    const getPDF = async () => {
        const response = await QuotationService.GetQuotationPDF(id)
        //console.log(response)

        if(response.success) {
            const blob = base64ToBlob(response.data, 'application/pdf');
            const url = await URL.createObjectURL(blob);

            setPDFQuotation(url)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'No se pudo cargar el documento',
                type: 'error'
            }) 
            //console.log(response)
        }
    }

    const getComments = async () => {
        const response = await FollowupService.GetComment(id)

        if(response.success) {
            const data = response.data
            const comments = data.reverse()

            setComments(comments)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }  
    }

    const getTags = async () => {
        const response = await TagsService.GetAllTags()

        if(response.success) {
            setStatus(response.data)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    const getStatus = async (id) => {
        //console.log(statusSelected)

        const response = await TagsService.GetTagFromQuotation(id)

        if(response.success) {
            const data = response.data
            
            if(data.length !== 0) setStatusSelected(data.tagId)
            else setStatusSelected('')
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }
    }

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Detalles de cotización'

        setOpenLoader(true)
        getQuotation()
        getComments()
        getTags()
        getStatus(id)
    }, [])

    const handleBackToHome = async () => {
        navigate('/home')
    }

    const handleGoToUpdate = async () => {
        navigate(`/cotizaciones/actualizar/${id}`)
    }

    const handleAproveQuotation = async () => {
        setOpenLoader(true)

        const response = await QuotationService.AproveQuotation(id)

        if(response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: 'Cotización aprobada exitosamente',
                type: 'success'
            }) 

            navigate(`/cotizaciones`)
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            }) 
            //console.log(response)
        }

        setOpenLoader(false)
    }

    const handleSendMail = async () => {
        setTextLoader('Enviando cotización')
        setOpenLoader(true)

        const response = await QuotationService.SendQuotationPDF(id)

        if(response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: 'Correo enviado de manera exitosa',
                type: 'success'
            })
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'Error al enviar correo, favor de intentar mas tarde.',
                type: 'error'
            }) 
            //console.log(response)
        }

        setOpenLoader(false)
        setTextLoader('')
    }

    const handleCountDownloads = async () => {
        const response = await QuotationService.CountDownloads(id)

        if(response.success) {
            //console.log(response)

            const blob = base64ToBlob(response.data, 'application/pdf');
            const name = quotation?.quotation?.quotationCode + ".pdf"

            saveAs(blob, name)

            // Update view
            setOpenLoader(true)
            getQuotation()
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'Error al descargar PDF, favor de intentar mas tarde.',
                type: 'error'
            }) 
            //console.log(response)
        }
    }

    /* Comments & status */
    const [status, setStatus] = useState([])
    const [statusSelected, setStatusSelected] = useState('')

    const [comments, setComments] = useState([])
    const [newComent, setNewComment] = useState({
        comment: '',
        newComent: false
    })

    const handleNewComment = async () => {
        //console.log(newComent)

        if(newComent.comment !== '') {
            const data = {
                idClient: quotation.customer.customerId,
                idQuotation: quotation.quotation.quotationId,
                comment: newComent.comment
            }

            const response = await FollowupService.AddComment(data)

            if(response.success) {
                await getComments()
                
                setNewComment({
                    comment: '',
                    newComent: false
                })
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
            }            
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: 'El comentario no puede estar vacio.',
                type: 'warning'
            }) 
        }
    }

    const handleUpdateStatus = async () => {
        //console.log('update status', statusSelected)

        setOpenLoader(true)

        const response = await TagsService.UpdateStatus(statusSelected, id)

        if(response.success) {
            setOpenAlert(true);
            setDataAlert({
                message: 'Estatus actualizado correctamente',
                type: 'success'
            })
        } else {
            setOpenAlert(true);
            setDataAlert({
                message: response.message,
                type: 'error'
            })
        }

        setOpenLoader(false)
    }

    const convertToTagName = ( tagId ) => {
        for (const i in status) {
            if (tagId === status[i].tagId) {
                
                return status[i].tag
            }
        }

        return 'Sin Estatus'
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>Cotización</Typography>
            </Container>
            <Container>
                <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>Cliente:</Typography>
                    <Typography variant="h6" style={{ paddingLeft: 48 }}>{ quotation?.customer?.name + ' ' + quotation?.customer?.lastName }</Typography>
                </Stack>
                <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>Fecha:</Typography>
                    <Typography variant="h6" style={{ paddingLeft: 57 }}>{ moment(quotation?.events?.eventDate).utc().format('dddd DD [de] MMMM [de] YYYY') } </Typography>
                </Stack>
                <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>Tipo Evento:</Typography>
                    <Typography variant="h6" style={{ paddingLeft: 0 }}>{ quotation?.eventTypes?.description }</Typography>
                </Stack>
                <Typography variant="h5" sx={{ textAlign: { xs: 'center', sm: 'right', md: 'right' }, paddingTop: 5, paddingBottom: 5 }} >Cotización { quotation?.quotation?.quotationCode }</Typography>
            </Container>
            
            <Container maxWidth="xl">
                <Grid container >
                    <Grid item xs={12} sm={12} md={8}>
                        <Container>
                            {pdfQuotation && <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                                <div className='webViewer'>
                                    <Viewer 
                                        fileUrl={pdfQuotation} 
                                        plugins={[
                                            // Register plugins
                                            defaultLayoutPluginInstance,
                                        ]}
                                    />
                                </div>
                            </Worker>}
                        </Container>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={4}>
                        <Container>
                            <Box>
                                {/* Items */}
                                <Container sx={{ margin: '1%', borderBottom: '1px solid' }}>
                                    <TableContainer sx={{paddingBottom: 3}}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1" style={{ fontWeight: 800}}>Total de items:</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">{ itemsQuantity.total }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">Items de linea:</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">{ itemsQuantity.active }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">Items personalizados:</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">{ itemsQuantity.custom }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">Costo:</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">{ totalPrice }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Container>

                                {/* Send options */}
                                <Container sx={{ margin: '1%', borderBottom: '1px solid' }}>
                                    <Stack direction="row" spacing={2} sx={{paddingTop: 3}}>
                                        <img
                                            alt="Send Options Icon"
                                            src={SendIconRoute}
                                            style={theme.avatarActions}
                                        />
                                        <Typography variant="h6" style={{ fontWeight: 800 }}>Opciones de Envío</Typography>
                                    </Stack>
                                    <Grid container spacing={4} sx={{ paddingBottom: 3, paddingTop: 3 }}>
                                        <Grid item xs={8} sm={6} md={8} lg={6} xl={6} >
                                            <Button
                                                startIcon={<DownloadIcon />}
                                                style={{ backgroundColor: '#DBD8E3', color: 'black', fontWeight: 800, height: '100%', width: '100%', fontSize: 11 }}
                                                onMouseDown={(e)=>e.preventDefault()} 
                                                onContextMenu={(e)=>e.preventDefault()}
                                                onClick={handleCountDownloads}
                                            >
                                                Descargar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={8} sm={6} md={8} lg={6} xl={6}>
                                            <a 
                                                className='whatsAppBtn'
                                                href={`https://wa.me/52${quotation?.customer?.mobile}/?text=${encodeURI(API.BASEURL + '/quotations/quotation/' + id + '/' + quotation?.quotation?.quotationCode + '.pdf')}`} 
                                                target='_blank' 
                                            >
                                                <Button
                                                    startIcon={<WhatsAppIcon />}
                                                    style={{ backgroundColor: '#DBD8E3', color: 'black', fontWeight: 800, height: '100%', width: '100%', fontSize: 11 }}
                                                >
                                                    WhatsApp
                                                </Button>
                                            </a>
                                        </Grid>
                                        <Grid item xs={8} sm={6} md={8} lg={6} xl={6}>
                                            <Button
                                                startIcon={<EmailIcon />}
                                                style={{ backgroundColor: '#DBD8E3', color: 'black', fontWeight: 800, height: '100%', width: '100%', fontSize: 11 }}
                                                onClick={handleSendMail}
                                            >
                                                Email
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>

                                {/* Quotation update */}
                                {quotation?.quotation?.status !== 'aproved' && <Container sx={{ margin: '1%', borderBottom: '1px solid' }}>
                                    <Stack direction="row" spacing={2} sx={{paddingTop: 3}}>
                                        <img
                                            alt="Update Quotation Icon"
                                            src={UpdateIconRoute}
                                            style={theme.avatarActions}
                                        />
                                        <Typography variant="h6" style={{ fontWeight: 800 }}>Actualizar cotización</Typography>
                                    </Stack>
                                    <Grid container spacing={4} sx={{ paddingBottom: 3, paddingTop: 3, }}>
                                        <Grid item xs={8} sm={6} md={8} lg={6} xl={6}>
                                            <Button
                                                startIcon={<UpdateIcon />}
                                                style={{ backgroundColor: '#DBD8E3', color: 'black', fontWeight: 800, height: '100%', width: '100%', fontSize: 11 }}
                                                onClick={handleGoToUpdate}
                                            >
                                                Actualizar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>}

                                {/* Client status */}
                                <Container sx={{ margin: '1%', borderBottom: '1px solid' }}>
                                    <Stack direction="row" spacing={2} sx={{paddingTop: 3}}>
                                        <img
                                            alt="Client Status Icon"
                                            src={ViewIconRoute}
                                            style={theme.avatarActions}
                                        />
                                        <Typography variant="h6" style={{ fontWeight: 800 }}>Estatus Cliente</Typography>
                                    </Stack>
                                    <TableContainer sx={{paddingBottom: 3}}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">Descargas:</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">{ quotation?.quotation?.downloads }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">Vistas Web:</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none'}}>
                                                        <Typography variant="subtitle1">{ quotation?.quotation?.visualizations }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Container>
                            </Box>
                            <Box style={{ textAlign: "right", paddingTop: 10 }}>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 1, mb: 2 }}
                                    style={{
                                        backgroundColor: "#000000",
                                        color: '#FFFFFF'
                                    }}
                                    onClick={handleBackToHome}
                                >
                                    Terminar
                                </Button>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            {/* follwoup  */}
            <Container maxWidth="xl" sx={{ mb: 8 }}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Container maxWidth="xl">
                                <Box sx={{ maxWidth: {xs: '100%', sm: '100%', md: '100%'} }}>
                                    <Typography variant="h5" style={{ fontWeight: 800, color: '#000000' }}>Seguimiento</Typography>
                                </Box>
                        </Container>

                        <Container maxWidth="lg">
                            <Box sx={{ maxWidth: {xs: '100%', sm: '100%', md: '100%'} }}>
                                
                                {/* Status */}
                                <Typography variant="body1" style={{ fontWeight: 800, color: '#000000' }} sx={{ mt: 4 }}>Estatus:</Typography>
                                <Grid container sx={{ pb: 4 }}>
                                    <Grid item container xs={12} sm={12} md={8} justify="center" alignItems="center" direction="row" columnSpacing={4}>
                                        <Grid item xs={12} sm={9} md={9}>
                                            <FormControl fullWidth size="small">
                                                <Select
                                                    fullWidth
                                                    displayEmpty
                                                    onChange={(e) => setStatusSelected(e.target.value)}
                                                    value=""
                                                    renderValue={
                                                        statusSelected !== '' ? () => <div>{convertToTagName(statusSelected)}</div> : () => <div style={{ color: '#aaaaaa' }}>sin status!</div>
                                                    }
                                                >
                                                    {
                                                        status.map((element) => {
                                                            return (
                                                                <MenuItem key={element.tagId} value={element.tagId}>{element.tag}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3} sx={{ textAlign: { xs: 'end', sm: 'end', md: 'start' } }}>
                                            <Button
                                                variant="contained"
                                                sx={{ mt: 1, mb: 2 }}
                                                style={{
                                                    backgroundColor: "#000000",
                                                    color: '#FFFFFF'
                                                }}
                                                onClick={handleUpdateStatus}
                                            >
                                                Actualizar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {comments.length !== 0 && <Typography variant="body1" style={{ fontWeight: 800, color: '#000000' }} sx={{ mt: 2 }}>Comentarios:</Typography>}
                                {/* Add new comment */}
                                {newComent.newComent && <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body2" sx={{ mt: 2 }}><b style={{ color: '#000000' }}>Nuevo comentario</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    minRows={4}
                                                    onInput = {(e) =>{
                                                        e.target.value = (e.target.value).toString().slice(0,1000)
                                                    }}
                                                    onChange={(e) => {
                                                        setNewComment({
                                                            ...newComent,
                                                            comment: e.target.value
                                                        })
                                                    }}
                                                />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} style={{ textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#000000",
                                                    color: '#FFFFFF'
                                                }}
                                                sx={{ mr: 2 }}
                                                onClick={handleNewComment}
                                            >
                                                Guardar
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#5d5b5b",
                                                    color: '#FFFFFF'
                                                }}
                                                sx={{ ml: 2 }}
                                                onClick={() => {
                                                    setNewComment({
                                                        comment: '',
                                                        newComent: false
                                                    })
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>}

                                {/* Comments */}
                                <Grid container spacing={1}>
                                    {!newComent.newComent && <Grid item xs={12} sm={12} md={4} sx={{ display: { md: 'none' } }}>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#000000",
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => {
                                                setNewComment({
                                                    ...newComent,
                                                    newComent: true
                                                })
                                            }}
                                        >
                                            Comentar
                                        </Button>
                                    </Grid>}

                                    {
                                        comments.map((comment, index) => {
                                            return (
                                                index === 0
                                                    ? <>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Typography variant="body2" sx={{ mt: 2 }}><b style={{ color: '#000000' }}>{ comment.Users?.name + ' ' + comment.Users?.lastName }</b> escribió el { moment(comment.commentDate).format('DD [de] MMMM [de] YYYY [a las] HH:mm') }</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8}>
                                                                <TextField
                                                                    className='textAreaComments'
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={4}
                                                                    disabled
                                                                    value={comment.comment}
                                                                />
                                                        </Grid>

                                                        {!newComent.newComent && <Grid item xs={12} sm={12} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: "#000000",
                                                                    color: '#FFFFFF'
                                                                }}
                                                                onClick={() => {
                                                                    setNewComment({
                                                                        ...newComent,
                                                                        newComent: true
                                                                    })
                                                                }}
                                                            >
                                                                Comentar
                                                            </Button>
                                                        </Grid>}
                                                    </>
                                                    : <>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Typography variant="body2" sx={{ mt: 2 }}><b style={{ color: '#000000' }}>{ comment.Users?.name + ' ' + comment.Users?.lastName }</b> escribio el { moment(comment.commentDate).format('DD [de] MMMM [de] YYYY [a las] HH:mm') }:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8}>
                                                                <TextField
                                                                    className='textAreaComments'
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={4}
                                                                    disabled
                                                                    value={comment.comment}
                                                                />
                                                        </Grid>
                                                    </>
                                            )
                                        })
                                    }

                                    {!newComent.newComent && comments.length === 0 && <Grid item xs={12} sm={12} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#000000",
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => {
                                                setNewComment({
                                                    ...newComent,
                                                    newComent: true
                                                })
                                            }}
                                        >
                                            Comentar
                                        </Button>
                                    </Grid>}

                                </Grid>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
        </ThemeProvider>
    );
}

export default QuotationDetails;