const errorCatalog = {
    0: 'Servicio no disponible', 
    1: 'Información faltante o tipos inválidos',
    2: 'El correo electronico ya fue registrado',
    3: 'Cine de preferencia invalido'
}

const handleError = (code, message) => {
    return {
        success: false,
        message: message
    }
}

export default handleError