import React, { useEffect, useState } from "react"

//Material UI imports
import { 
    Container,
    CssBaseline, 
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Components imports
import NavBar from "./../components/NavBarComponent/NavBarComponent.jsx"
import { useNavigate, useParams } from "react-router-dom"

import Alerts from './../components/alerts'
import LoaderScreen from "./../components/loaderScreen"

//Services import
import * as QuotationsService from './../api/quotations'
import * as ItemsService from './../api/items'

// Import component to stepper
import NewQuotation from "./../components/QuotationSteps/new_quotation_step_one"
import NewQuotationStepTwo from "./../components/QuotationSteps/new_quotation_step_two"

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

const theme = createTheme({
    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 5,
        fontWeight: 800
    },
});

const generateRandomString = (num) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1= '';
    const charactersLength = characters.length;
    for ( let i = 0; i < num; i++ ) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
}

const steps = ["Datos del evento", "Servicios para el evento"];

const NewQuotationStepper = () => {
    const { id } = useParams()
    let navigate = useNavigate()
    const [title, setTitle] = useState('Nueva Cotización')
    const [buttonEnd, setButtonEnd] = useState('Crear cotización')

    /* Functions to stepper */
    const [activeStep, setActiveStep] = useState(0)

    const handleNext = async () => {
        if(activeStep === 0) {
            if(
                eventDetails.eventDate !== '' &&
                eventDetails.eventType !== null &&
                //eventDetails.eventDetails !== '' &&
                eventDetails.guestQuantity !== 0 &&
                //quotationCode.quotationCode !== ''&&
                eventDetails.eventPlace !== ''
            ) { 
                
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSaveDraft(true)
                verifyCode();                
               

            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                }) 
            }
        } else if(activeStep === (steps.length - 1)) {
            if(itemsList.length !== 0) {
                
                setTextLoader('Generando cotización')
                setOpenLoader(true)
                const response = await QuotationsService.UpdateQuotation(eventDetails, quotationCode, itemsList, quotationId, quotationWithDetails)

                console.log(response)
                
                if(response.success) {
                    const quotationId = response.data

                    setOpenAlert(true);
                    setDataAlert({
                        message: 'Cotización actualizada de manera exitosa',
                        type: 'success'
                    }) 

                    navigate(`/cotizaciones/detalles/${quotationId}`);
                } else {
                    setOpenAlert(true);
                    setDataAlert({
                        message: response.message,
                        type: 'error'
                    }) 
                    //console.log(response)
                }

                setTextLoader('')
                setOpenLoader(false)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Debe agregarse al menos un item a la lista',
                    type: 'error'
                }) 
            }

        }
    }

    const handleNextUpdate = async () => {
        if(activeStep === 0) {
            if(
                // Validation of quotation details
                eventDetails.customer !== null &&
                eventDetails.eventDate !== '' &&
                eventDetails.eventType !== null &&
                //eventDetails.eventDetails !== '' &&
                eventDetails.guestQuantity !== 0 &&
                eventDetails.eventPlace !== '' &&
                // Validation of quotation code
                quotationCode.quotationCode !== ''
            ) { 
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                }) 
            }
        } else if(activeStep === (steps.length - 1)) {
            if(itemsList.length !== 0) {
                setTextLoader('Generando cotización')
                setOpenLoader(true)

                const response = await QuotationsService.UpdateQuotation(eventDetails, quotationCode, itemsList, id, quotationWithDetails)
                console.log(response)
                
                if(response.success) {
                    const quotationId = response.data

                    setOpenAlert(true);
                    setDataAlert({
                        message: 'Cotización actualizada de manera exitosa',
                        type: 'success'
                    }) 

                    navigate(`/cotizaciones/detalles/${quotationId}`);
                } else {
                    setOpenAlert(true);
                    setDataAlert({
                        message: response.message,
                        type: 'error'
                    }) 
                    //console.log(response)
                }

                setTextLoader('')
                setOpenLoader(false)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: 'Debe agregarse al menos un item a la lista',
                    type: 'error'
                }) 
            }
        }
    }
                

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    };
   
    /* Variables to Alerts */
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    /* Functions to event details */
    const [quotation, setQuotation] = useState([])
    const [customers, setCustomers] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [eventDetails, setEventDetails] = useState({
        customer: null,
        eventDate: '',
        eventType: null,
        eventDetails: '',
        guestQuantity: 0,
        eventPlace: ''
    })
    // Save quotationId from the first step
    const [quotationId, setQuotationId] = useState(null);

    const [quotationCode, setQuotationCode] = useState({
        quotationCode: '',
        codeId: ''
    })
    const [items, setItems] = useState([])
    const [itemCustomValue, setItemCustomValue] = useState({
        name: '',
        haveDescription: false,
        description: '',
        price: null,
        quantity: 0
    })
    const [itemsCustom, setItemsCustom] = useState([])
    const [itemsList, setItemsList] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [quotationWithDetails, setQuotationWithDetails] = useState(false)
    
    const [saveDraft,setSaveDraft]=useState(false)
    const verifyCode = () => {
        // let exist = false
        // while(!exist) {
        //     const codeRandom = generateRandomString(3)
        //     const code = 'MX' + moment().format('YYYYMMDD') + '-' + codeRandom

        //     const response = await QuotationsService.VerifyCode(code)
        //     if(response.success) {
        //         exist = response.success
        //         setQuotationCode({
        //             quotationCode: code,
        //             codeId: codeRandom
        //         })
        //     }
        // }

        if(eventDetails.customer !== null || eventDetails.eventType !== null) {
            const code = eventDetails.customer.name.replace(/ /g, '') + '-' + eventDetails.eventType.description.replace(/ /g, '') + '-' + moment().format('YYYYMMDD')
            setQuotationCode({
                quotationCode: code,
                codeId: ''
            })
        } else {
            //console.log("No hay datos para generar el código")
        }
    }
    useEffect(()=>{
        const createDraft= async()=>{
            const quotationResponse = await QuotationsService.CreateQuotationFirstStep(eventDetails, quotationCode);

            if (quotationResponse.success) {
                const qId = quotationResponse.data;
                setQuotationId(qId);
                
                console.log('Cotización creada en el primer paso. ID:', qId);

            } else {

                console.log('Error al crear la cotización en el primer paso:', quotationResponse.error);
            }

        }
        console.log(saveDraft)
        if(saveDraft){
            createDraft();
        }
        
    },[quotationCode])

    
    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Nueva cotización'

        const getDataUpdateQuotation = async () => {
            const response = await QuotationsService.GetQuotationDetails(id)

            if(response.success) {
                setSaveDraft(false)
                //console.log(response)
                const data = response.data

                setQuotation(data.quotation)

                setQuotationCode({
                    quotationCode: data.quotation.quotationCode,
                    codeId: data.quotation.quotationCode.split('-')[1]
                })

                setEventDetails({
                    customer: data.customer,
                    eventDate: moment(data.events.eventDate).utc().format('YYYY-MM-DD'),
                    eventType: data.eventTypes,
                    eventDetails: data.events.eventDetails,
                    guestQuantity: data.events.guestQuantity,
                    eventPlace: data.events.eventPlace
                })

                /* Get items and generate itemList*/
                const responseItems = await ItemsService.GetItems('')
                if(responseItems.success) {
                    let newItems = []
                    for(var i in responseItems.data) {
                        const item = responseItems.data[i]

                        newItems.push({
                            ...item,
                            itemAdd: 0,
                            value: 0,
                            priceAdd: 0,
                            showDescription: false
                        })
                    }
                    //console.log(newItems)

                    const itemsQuotation = data.items
                    //console.log(items, itemsQuotation)
                    
                    let itemsActiveToAdd = []
                    let itemsCustomToAdd = []
                    for(var i in itemsQuotation) {
                        const item = itemsQuotation[i]

                        //console.log(newItems)
                        switch(item.status) {
                            case 'active':
                                //let newItemsActive = []
                                await newItems.forEach(activeItem => {
                                    if(item.itemId === activeItem.itemId) {
                                        itemsActiveToAdd.push({
                                            ...activeItem,
                                            itemAdd: item.quantity,
                                            //activeItem.quantityAvailable -= item.quantity

                                            /* const disccount = (activeItem.price * activeItem.disccount) / 100
                                            activeItem.priceAdd = (activeItem.itemAdd * (activeItem.price - disccount)) */
                                            priceAdd: (item.quantity * item.price),
                                            showDescription: false,
                                            price: item.price,
                                            description: item.description
                                        })
                                    }
                                })

                                //console.log(newItemsActive)
                                //itemsActiveToAdd = newItemsActive
                                break
                            case 'custom':                        
                                const totalItemsCustom = itemsCustomToAdd.length
                                const itemCustomIndex = `c${totalItemsCustom}`

                                const custom = {
                                    name: item.name,
                                    description: item.description,
                                    disccount: 0,
                                    itemId: itemCustomIndex,
                                    price: item.price,
                                    priceAdd: (item.price * item.quantity),
                                    quantityAvailable: item.quantity,
                                    itemAdd: item.quantity,
                                    status: 'custom',
                                    showDescription: false
                                }

                                itemsCustomToAdd.push(custom)
                                break
                            default:
                                break
                        }
                    }

                    let price = 0
                    let itemToAdd = itemsList

                    // Add items active to itemList
                    await itemsActiveToAdd.forEach(async item => {
                        if(item.itemAdd !== 0) {
                            itemToAdd.push({
                                ...item
                            })
                            price += item.priceAdd
                        }
                    })

                    // Add items custom to itemList
                    await itemsCustomToAdd.forEach(async item => {
                        itemToAdd.push({
                            ...item
                        })
                        price += item.priceAdd
                    })

                    setItems(newItems)
                    setItemsCustom(itemsCustomToAdd)
                    setItemsList(itemToAdd)
                    setTotalPrice(price)
                } else {
                    setOpenAlert(true);
                    setDataAlert({
                        message: responseItems.message,
                        type: 'error'
                    }) 
                    //console.log(response)
                }
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
                setSaveDraft(true)
            }

            setOpenLoader(false)
        }

        if(id) {
            document.title = 'ELISA By Inova Evento | Actualizar cotización'

            setOpenLoader(true)
            setTitle('Actualizar Cotización')
            setButtonEnd('Actualizar Cotización')
            
            getDataUpdateQuotation()
        } else {
            verifyCode()

        }
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <NavBar />

            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>{ title }</Typography>
                {
                    quotationCode.quotationCode === ''
                        ? <Typography variant="h5" align="center"></Typography>
                        : <Typography variant="h5" align="center">Cotización { quotationCode.quotationCode }</Typography>
                }
            </Container>
            
            <Container maxWidth='xl'>
                <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} style={{ marginBottom: '45px', marginTop: '45px' }} alternativeLabel>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {/* Content of stepper */}
                    <React.Fragment>
                        {activeStep !== steps.length - 1 
                            ?   <NewQuotation 
                                    customers={customers} 
                                    setCustomers={setCustomers} 
                                    eventTypes={eventTypes} 
                                    setEventTypes={setEventTypes} 
                                    eventDetails={eventDetails} 
                                    setEventDetails={setEventDetails} 
                                    id={id} 
                                    openAlert={openAlert} 
                                    setOpenAlert={setOpenAlert} 
                                    dataAlert={dataAlert} 
                                    setDataAlert={setDataAlert} 
                                    openLoader={openLoader} 
                                    setOpenLoader={setOpenLoader} 
                                    textLoader={textLoader} 
                                    setTextLoader={setTextLoader} 
                                />
                            :   <NewQuotationStepTwo 
                                    eventDetails={eventDetails} 
                                    setEventDetails={setEventDetails} 
                                    items={items} 
                                    setItems={setItems} 
                                    itemCustomValue={itemCustomValue} 
                                    setItemCustomValue={setItemCustomValue} 
                                    itemsCustom={itemsCustom} 
                                    setItemsCustom={setItemsCustom} 
                                    itemsList={itemsList} 
                                    setItemsList={setItemsList} 
                                    totalPrice={totalPrice} 
                                    setTotalPrice={setTotalPrice} 
                                    id={id} 
                                    openAlert={openAlert} 
                                    setOpenAlert={setOpenAlert} 
                                    dataAlert={dataAlert} 
                                    setDataAlert={setDataAlert} 
                                    openLoader={openLoader} 
                                    setOpenLoader={setOpenLoader} 
                                    textLoader={textLoader} 
                                    setTextLoader={setTextLoader} 
                                    quotationWithDetails={quotationWithDetails}
                                    setQuotationWithDetails={setQuotationWithDetails}
                                />
                        }

                        <Box sx={{ display: "flex", flexDirection: "row", py: 4 }}>
                            <Button
                                color="inherit"
                                variant="contained"
                                size="small"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Regresar
                            </Button>

                            <Box sx={{ flex: "1 1 auto" }} />

                            <Button 
                                variant="contained"
                                size="small"
                                onClick={id ? handleNextUpdate : handleNext }
                            >
                                {activeStep === steps.length - 1 ? buttonEnd : "Siguiente"}
                            </Button>
                        </Box>
                    </React.Fragment>
                </Box>
            </Container>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
        </ThemeProvider>
    )
}

export default NewQuotationStepper