import React, { useEffect, useState } from 'react'

//Material UI imports
import { 
    Typography, 
    Container, 
    Stack, 
    Box, 
    TextField, 
    IconButton, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TableCell, 
    Table, 
    TableBody,
    FormControlLabel,
    Checkbox,
    InputAdornment,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Assets imports
import AddIconRoute from "./../../assets/icons/AddButton.png"
import DeleteIconRoute from "./../../assets/icons/Delete.png"

//CSS imports
import "./../../assets/css/fonts.css"
import './../../assets/css/styles.css'

//Services import
import * as ItemsService from '../../api/items'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')


const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 30,
        paddingBottom: 30,
        fontWeight: 800
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 30,
        width: 30,
    },

    buttonAddPersonalizedItem: {
        backgroundColor: "#5C5470",
        align: "right",
        marginBottom: 10,
    }
});

const NewQuotationStepTwo = ({ 
    eventDetails, 
    setEventDetails, 
    items, 
    setItems, 
    itemCustomValue, 
    setItemCustomValue, 
    itemsCustom, 
    setItemsCustom, 
    itemsList, 
    setItemsList, 
    totalPrice, 
    setTotalPrice, 
    id,
    openAlert, 
    setOpenAlert, 
    dataAlert, 
    setDataAlert, 
    openLoader, 
    setOpenLoader, 
    textLoader, 
    setTextLoader,
    quotationWithDetails,
    setQuotationWithDetails
}) => {
    useEffect(() => {
        const getItems = async () => {
            const response = await ItemsService.GetItems('')

            if(response.success) {
                let newItems = []
                for(var i in response.data) {
                    const item = response.data[i]

                    newItems.push({
                        ...item,
                        itemAdd: 0,
                        value: 0,
                        priceAdd: 0
                    })
                }
                
                //console.log(newItems)
                setItems(newItems)
            } else {
                setOpenAlert(true);
                setDataAlert({
                    message: response.message,
                    type: 'error'
                }) 
                //console.log(response)
            }

            setOpenLoader(false)
        }

        const isNotANumber = isNaN(parseInt(id))
        /* Define what vie load 
        @params
            * isNumber
                * if not is a number then load items
        */
        if(isNotANumber) {
            setOpenLoader(true)
            getItems()
        }
    }, [])

    const addItemList = async (e, id) => {
        e.preventDefault()
        //console.log(items)

        let itemToAdd = itemsList
        for(var i in items) {
            const item = items[i]

            if(item.itemId === id) {
                //console.log(item)
                //console.log(price, item.itemAdd, item.price, (item.itemAdd * item.price))
                
                const index = itemToAdd.findIndex(itemToFind => itemToFind.itemId === id);
                //console.log((index>=0?itemToAdd[index].itemAdd:"vacio"), item.itemAdd)
                index >= 0 
                ? itemToAdd[index] = {
                    ...item,
                    itemAdd: itemToAdd[index].itemAdd + item.itemAdd,
                    price: itemToAdd[index].price,
                    description: itemToAdd[index].description
                }
                :itemToAdd.push({
                    ...item,
                    itemAdd: item.itemAdd
                })
                
                //console.log(itemToAdd)

                let price = 0
                for(var j in itemToAdd) {
                    /* const disccount = (itemToAdd[j].price * itemToAdd[j].disccount) / 100
                    itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount))
                    price += (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount)) */

                    itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * itemToAdd[j].price)
                    price += (itemToAdd[j].itemAdd * itemToAdd[j].price)
                }

                setItemsList(itemToAdd)
                setTotalPrice(price)
            } 
        }

        //console.log(itemsList)

        let tempItems = []
        await items.forEach((item) => {
            //if(item.quantityAvailable !== item.itemAdd) {
                //item.quantityAvailable = item.quantityAvailable - item.itemAdd
                if(item.itemId === id){
                    item.value = 0
                    item.itemAdd = 0  
                } 
                tempItems.push(item)
            //}            
        })
        //console.log(tempItems)
        setItems(tempItems)
    }

    const addItemCustom = async () => {
        //console.log("custom")
        //console.log(itemCustomValue)
        //console.log(itemsCustom)

        const totalItemsCustom = itemsCustom.length
        const itemCustomIndex = `c${totalItemsCustom}`

        const newItemCustom = [
            ...itemsCustom,
            {
                name: itemCustomValue.name,
                description: itemCustomValue.haveDescription ? itemCustomValue.description : '',
                disccount: 0,
                itemId: itemCustomIndex,
                price: itemCustomValue.price,
                priceAdd: (itemCustomValue.price * itemCustomValue.quantity),
                quantityAvailable: itemCustomValue.quantity,
                itemAdd: itemCustomValue.quantity,
                status: 'custom',
                showDescription: false
            }
        ]

        //console.log(newItemCustom)

        let itemToAdd = itemsList
        for(var i in newItemCustom) {
            const item = newItemCustom[i]
            
            //console.log(price, item.itemAdd, item.price, (item.itemAdd * item.price))
            
            const index = itemToAdd.findIndex(itemToFind => itemToFind.itemId ===item.itemId);
            //console.log((index>=0?itemToAdd[index].itemAdd:"vacio"), item.itemAdd)
            index >= 0 
            // No se actualiza el numero de items personalizado, siempre es 1
            ? itemToAdd[index] = {
                ...item,
                itemAdd: itemToAdd[index].itemAdd
            }
            :itemToAdd.push({
                ...item,
                itemAdd: item.itemAdd
            })
            
            //console.log(itemToAdd)

            let price = 0
            for(var j in itemToAdd) {
                /* const disccount = (itemToAdd[j].price * itemToAdd[j].disccount) / 100
                itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount))
                price += (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount)) */

                itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * itemToAdd[j].price)
                price += (itemToAdd[j].itemAdd * itemToAdd[j].price)
            }

            setItemsList(itemToAdd)
            setTotalPrice(price)
        }

        setItemsCustom(newItemCustom)
        setItemCustomValue({
            name: '',
            haveDescription: false,
            description: '',
            price: null,
            quantity: 0
        })

        //console.log(itemsList)
    }

    const deleteOfListItem = async (itemDelete) => {
        let price = totalPrice
        if(itemDelete.status === 'active') {
            const index = items.findIndex(items => items.itemId === itemDelete.itemId);
            //console.log(index)

            let tempItems = []
            if(index >= 0) {
                //console.log("entro aqui if")
                await items.forEach((item) => {
                    if(item.itemId === itemDelete.itemId) {
                        item.quantityAvailable = item.quantityAvailable + item.itemAdd
                        item.itemAdd = 0

                        price -= itemDelete.priceAdd
                        item.priceAdd = 0
                    }

                    tempItems.push(item)
                })
            } else {
                //console.log("entro aqui else")
                tempItems = items
                //console.log(itemDelete)

                itemDelete.itemAdd = 0
                itemDelete.value = 0

                price -= itemDelete.priceAdd
                itemDelete.priceAdd = 0

                tempItems.push(itemDelete)

                tempItems.sort((a, b) => {
                    if(a.itemId > b.itemId) return 1
                    if(a.itemId < b.itemId) return -1
    
                    return 0
                })
            }
            //console.log(tempItems)
            
            setItems(tempItems)
        } else {
            price -= itemDelete.priceAdd
        }

        const indexList = itemsList.findIndex(items => items.itemId === itemDelete.itemId);
        let newItemList = []
        for(var i in itemsList) {
            if(parseInt(i) !== indexList) {
                newItemList.push(itemsList[i])
            }
        }
        //console.log(indexList, itemsList, itemDelete, newItemList)

        setItemsList(newItemList)
        setTotalPrice(price)
    }

    const updateItemsList = async (e, id, quantity) => {
        e.preventDefault()
        
        let itemToAdd = itemsList
        for(var i in itemToAdd) {
            const item = itemToAdd[i]

            if(item.itemId.toString() === id.toString()) {
                //console.log(item)
                //console.log(price, item.itemAdd, item.price, (item.itemAdd * item.price))
                
                const index = itemToAdd.findIndex(itemToFind => itemToFind.itemId.toString() === id.toString());
                //console.log((index>=0?itemToAdd[index].itemAdd:"vacio"), item.itemAdd)

                itemToAdd[index] = {
                    ...item,
                    itemAdd: parseInt(quantity)
                }
                
                //console.log(itemToAdd)

                let price = 0
                for(var j in itemToAdd) {
                    /* const disccount = (itemToAdd[j].price * itemToAdd[j].disccount) / 100
                    itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount))
                    price += (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount)) */

                    itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * itemToAdd[j].price)
                    price += (itemToAdd[j].itemAdd * itemToAdd[j].price)
                }

                setItemsList(itemToAdd)
                setTotalPrice(price)
            } 
        }
    }

    const updateUnitPrice = async (e, id, newPrice) => {
        e.preventDefault()
        
        let itemToAdd = itemsList
        for(var i in itemToAdd) {
            const item = itemToAdd[i]

            if(item.itemId.toString() === id.toString()) {
                //console.log(item)
                //console.log(price, item.itemAdd, item.price, (item.itemAdd * item.price))
                
                const index = itemToAdd.findIndex(itemToFind => itemToFind.itemId.toString() === id.toString());
                //console.log((index>=0?itemToAdd[index].itemAdd:"vacio"), item.itemAdd)

                itemToAdd[index] = {
                    ...item,
                    price: newPrice
                }
                
                //console.log(itemToAdd)

                let price = 0
                for(var j in itemToAdd) {
                    /* const disccount = (itemToAdd[j].price * itemToAdd[j].disccount) / 100
                    itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount))
                    price += (itemToAdd[j].itemAdd * (itemToAdd[j].price - disccount)) */

                    itemToAdd[j].priceAdd = (itemToAdd[j].itemAdd * itemToAdd[j].price)
                    price += (itemToAdd[j].itemAdd * itemToAdd[j].price)
                }

                setItemsList(itemToAdd)
                setTotalPrice(price)
            } 
        }
    }

    const showDescriptionItem = async (e, id, checked) => {
        e.preventDefault()

        let itemToAdd = itemsList
        let itemsUpdated = []
        for(var i in itemToAdd) {
            const item = itemToAdd[i]

            if(item.itemId.toString() === id.toString()) {
                //console.log(item)

                itemsUpdated.push({
                    ...item,
                    showDescription: checked
                })

            } else {
                itemsUpdated.push({
                    ...item
                })
            }
        }

        setItemsList(itemsUpdated)
    }

    const updateDescriptionItem = async (e, id, newDescription) => {
        e.preventDefault()

        let itemToAdd = itemsList
        let itemsUpdated = []
        for(var i in itemToAdd) {
            const item = itemToAdd[i]

            if(item.itemId.toString() === id.toString()) {
                //console.log(item)

                itemsUpdated.push({
                    ...item,
                    description: newDescription
                })

            } else {
                itemsUpdated.push({
                    ...item
                })
            }
        }

        setItemsList(itemsUpdated)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth='xl'>
                <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>Cliente:</Typography>
                    <Typography variant="h6" style={{ paddingLeft: 48 }}>{ eventDetails.customer?.name + ' ' + eventDetails.customer?.lastName }</Typography>
                </Stack>
                <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>Fecha:</Typography>
                    <Typography variant="h6" style={{ paddingLeft: 57 }}>{ moment(eventDetails.eventDate).format('dddd DD [de] MMMM [de] YYYY') }</Typography>
                </Stack>
                <Stack direction="row" spacing={2} style={{ marginTop: 15 }}>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>Tipo Evento:</Typography>
                    <Typography variant="h6" style={{ paddingLeft: 0 }}>{ eventDetails.eventType?.description}</Typography>
                </Stack>
            </Container>
            <Container maxWidth='xl' sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, pt: 4 }}>
                {/* List of items to add */}
                <Container>
                    <Box sx={{ border: '1px solid', borderRadius: '10px' }}>
                        <Container sx={{ margin: '1%' }}>
                            <Stack direction="column" spacing={1}>
                                {/* Items Custom */}
                                <Typography variant="h6" style={{ fontWeight: 800 }}>Items personalizados</Typography>
                                <Stack direction="row" spacing={0}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="addPersonalizedItem"
                                        name="addPersonalizedItem"
                                        size="small"
                                        color="independece"
                                        type="text"
                                        placeholder="Introduce el concepto"
                                        value={itemCustomValue.name}
                                        onChange={(e) => {
                                            setItemCustomValue({
                                                ...itemCustomValue,
                                                name: e.target.value
                                            })
                                        }}
                                    />
                                    <IconButton 
                                        sx={{ marginRight: 2 }}
                                        disabled={itemCustomValue.name === '' || itemCustomValue.price === null || itemCustomValue.quantity === 0}
                                        onClick={addItemCustom}
                                    >
                                        <img
                                            alt="Add Icon"
                                            src={AddIconRoute}
                                            style={theme.avatarActions}
                                        />
                                    </IconButton>
                                </Stack>
                                <Stack direction="row" spacing={0} style={{ margin: '0 60px 0 0' }}>
                                    <TextField
                                        margin="normal"
                                        sx={{ maxWidth: '50%', paddingRight: 1, marginTop: 0 }}
                                        id="addPersonalizedItemQuantity"
                                        name="addPersonalizedItemQuantity"
                                        size="small"
                                        color="independece"
                                        type="text"
                                        placeholder="Cantidad"
                                        value={itemCustomValue.quantity !== 0 ? itemCustomValue.quantity : ''}
                                        onChange={(e) => {
                                            setItemCustomValue({
                                                ...itemCustomValue,
                                                quantity: parseInt(e.target.value)
                                            })
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        sx={{ maxWidth: '50%', paddingLeft: 1, marginTop: 0 }}
                                        id="addPersonalizedItemPrice"
                                        name="addPersonalizedItemPrice"
                                        size="small"
                                        color="independece"
                                        type="text"
                                        placeholder="Precio"
                                        value={itemCustomValue.price ? itemCustomValue.price : ''}
                                        onChange={(e) => {
                                            setItemCustomValue({
                                                ...itemCustomValue,
                                                price: parseInt(e.target.value)
                                            })
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={0} style={{ margin: '0 0 0 0' }}>
                                    <FormControlLabel
                                        value="Descripcion"
                                        control={
                                            <Checkbox 
                                                checked={itemCustomValue.haveDescription}
                                                onChange={(e) => {
                                                    setItemCustomValue({
                                                        ...itemCustomValue,
                                                        haveDescription: e.target.checked
                                                    })
                                                }}
                                            />
                                        }
                                        label="Descripcion"
                                    />
                                </Stack>
                                {itemCustomValue.haveDescription && <Stack direction="row" spacing={0} style={{ margin: '0 75px 0 0' }}>
                                    <TextField
                                        style={{ margin: 0 }}
                                        margin="normal"
                                        fullWidth
                                        id="addPersonalizedItemDescription"
                                        name="addPersonalizedItemDescription"
                                        size="small"
                                        color="independece"
                                        type="text"
                                        multiline
                                        rows={4}
                                        placeholder="Introduce la descripción"
                                        value={itemCustomValue.description}
                                        onInput = {(e) =>{
                                            e.target.value = (e.target.value).toString().slice(0,1000)
                                        }}
                                        onChange={(e) => {
                                            setItemCustomValue({
                                                ...itemCustomValue,
                                                description: e.target.value
                                            })
                                        }}
                                    />
                                </Stack>}

                                {/* Items registrered */}
                                <Typography variant="h6" style={{ fontWeight: 800 }}>Items de Serie</Typography>
                                <TableContainer sx={{ maxHeight: '500px', minHeight: '500px' }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ minWidth: '200px' }}>Descripción</TableCell>
                                                <TableCell>Cantidad</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tableItemsQuotation'>
                                            {items.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <TextField
                                                            margin="normal"
                                                            fullWidth
                                                            size="small"
                                                            className='inputsDisabled'
                                                            type="text"
                                                            value={item.name}
                                                            disabled
                                                        />
                                                    </TableCell>
                                                    <TableCell >
                                                        <TextField
                                                            className="inputQuantityAdd"
                                                            margin="normal"
                                                            size="small"
                                                            color="independece"
                                                            type="number"
                                                            InputProps={{ inputProps: { min: 0/* , max: item.quantityAvailable */ } }}
                                                            value={ item.value }
                                                            onChange={(e) => {
                                                                let tempItems = []
                                                                for(var i in items) {
                                                                    let itemT = items[i]

                                                                    if(itemT.itemId === item.itemId) {
                                                                        itemT.itemAdd = parseInt(e.target.value)
                                                                        itemT.value = parseInt(e.target.value)
                                                                    }

                                                                    tempItems.push(itemT)
                                                                }
                                                                setItems(tempItems)
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            disabled={item.itemAdd === 0 /* || item.itemAdd > item.quantityAvailable */}
                                                            onClick={(e) => addItemList(e, item.itemId)}
                                                        >
                                                            <img
                                                                alt="Add Icon"
                                                                src={AddIconRoute}
                                                                style={theme.avatarActions}
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </Container>
                    </Box>
                </Container>

                {/* List of items added */}
                <Container>
                    <Box sx={{ border: '1px solid', borderRadius: '10px' }}>
                        <Container sx={{ margin: '1%' }}>
                            <TableContainer sx={{ maxHeight: '650px', minHeight: '650px' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item</TableCell>
                                            <TableCell style={{ minWidth: '150px', maxWidth: '200px' }}>Cantidad</TableCell>
                                            <TableCell style={{ minWidth: '150px', maxWidth: '200px' }}>Costo Unitario</TableCell>
                                            <TableCell >Costo Total</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {itemsList.map((item, index) => (
                                            <>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: '5px', borderBottom: 'none' }}>{item.name}</TableCell>
                                                    <TableCell style={{ paddingBottom: '5px', borderBottom: 'none'  }}>
                                                        <TextField
                                                            className="inputQuantityAdd"
                                                            margin="normal"
                                                            //id={item.itemId}
                                                            size="small"
                                                            color="independece"
                                                            type="number"
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                            value={ item.itemAdd }
                                                            onChange={(e) => { updateItemsList(e, item.itemId, e.target.value) }}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ paddingBottom: '5px', borderBottom: 'none'  }}>
                                                        <TextField
                                                            fullWidth
                                                            margin="normal"
                                                            //id={item.itemId}
                                                            size="small"
                                                            color="independece"
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: { 
                                                                    min: 1,
                                                                    step: 1
                                                                },
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                            }}
                                                            value={ item.price }
                                                            onChange={(e) => { updateUnitPrice(e, item.itemId, e.target.value) }}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ paddingBottom: '5px', borderBottom: 'none'  }}>${ Number(item.priceAdd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</TableCell>
                                                    <TableCell style={{ paddingBottom: '5px', borderBottom: 'none'  }}>
                                                        <Stack direction="row" spacing={2}>
                                                            <IconButton
                                                                onClick={() => deleteOfListItem(item)}
                                                            >
                                                                <img
                                                                    alt="Delete Icon"
                                                                    src={DeleteIconRoute}
                                                                    style={theme.avatarActions}
                                                                    
                                                                />
                                                            </IconButton>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={5} style={item.showDescription ? { paddingTop: 0, paddingBottom: 0, borderBottom: 'none' } : { paddingTop: 0, paddingBottom: 0 }}>
                                                        <FormControlLabel
                                                            value="Descripcion"
                                                            control={
                                                                <Checkbox 
                                                                    checked={item.showDescription}
                                                                    onChange={(e) => { showDescriptionItem(e, item.itemId, e.target.checked) }}
                                                                />
                                                            }
                                                            label="Descripcion"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                {item.showDescription && <TableRow>
                                                    <TableCell colSpan={5} style={{ paddingTop: 0, paddingBottom: 15 }}>
                                                        <Stack direction="row" spacing={0}>
                                                            <TextField
                                                                style={{ margin: 0 }}
                                                                margin="normal"
                                                                fullWidth
                                                                id="addPersonalizedItemDescription"
                                                                name="addPersonalizedItemDescription"
                                                                size="small"
                                                                color="independece"
                                                                type="text"
                                                                multiline
                                                                rows={4}
                                                                placeholder="Introduce la descripción"
                                                                value={item.description}
                                                                onInput = {(e) =>{
                                                                    e.target.value = (e.target.value).toString().slice(0,1000)
                                                                }}
                                                                onChange={(e) => { updateDescriptionItem(e, item.itemId, e.target.value) }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>}
                                            </>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={2} />
                                            <TableCell colSpan={1}>
                                                <Typography variant="h6" style={{ fontWeight: 700 }}>Total:</Typography>
                                            </TableCell>
                                            <TableCell>${ Number(totalPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</TableCell>
                                        </TableRow>
                                    
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </Box>

                    <FormControlLabel
                        value="Incluir precios unitarios en la cotización"
                        control={
                            <Checkbox 
                                checked={quotationWithDetails}
                                onChange={(e) => {
                                    setQuotationWithDetails(e.target.checked)
                                }}
                            />
                        }
                        label="Incluir precio total por items en la cotización"
                    />
                </Container>
            </Container>
        </ThemeProvider>
    );
}

export default NewQuotationStepTwo;