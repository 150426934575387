import React, { useState } from "react";

//Material UI imports
import {
    Backdrop,
    CircularProgress
} from "@mui/material";

//CSS imports
import "../assets/css/fonts.css"
import "../assets/css/styles.css"

import loader from './../assets/imgs/Loader.gif'

const LoaderScreen = ({open, text = ''}) => {
    return (
        <Backdrop
            className="loaderScreen"
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <img 
                className="loaderScreen" 
                src={loader} 
                alt="loader" 
            />
            {text !== '' && <div>
                <CircularProgress color="inherit" />
                <span>
                    <strong>{text}</strong>
                </span>
            </div>}
        </Backdrop>
    )
}

export default LoaderScreen