import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";

//Material UI imports
import { Typography, CssBaseline, Container, Card, CardMedia, CardContent, CardActionArea, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link } from "react-router-dom";

//CSS imports
import "./../assets/css/fonts.css"

//Components imports
import NavBar from "./../components/NavBarComponent/NavBarComponent.jsx"

//Assets imports
import QuotationsIconRoute from "../assets/icons/Dollar.png";
import ItemIconRoute from "../assets/icons/Item.png";
import UserIconRoute from "../assets/icons/User.png";
import ContractIconRoute from '../assets/icons/Contracts.png'

const cards = [
    {
        cardNumber: 1,
        cardSlug: "quotations",
        cardName: "Cotizaciones",
        cardIMG: QuotationsIconRoute,
        cardAltIMG: "Quotations Icon",
        cardLink: "/cotizaciones"
    },
    {
        cardNumber: 2,
        cardSlug: "items",
        cardName: "Catalogo de servicios",
        cardIMG: ItemIconRoute,
        cardAltIMG: "Items Icon",
        cardLink: "/items"
    },
    {
        cardNumber: 3,
        cardSlug: "customers",
        cardName: "Clientes",
        cardIMG: UserIconRoute,
        cardAltIMG: "Clients Icon",
        cardLink: "/clientes"
    },
    {
        cardNumber: 4,
        cardSlug: "contracts",
        cardName: "Contratos",
        cardIMG: ContractIconRoute,
        cardAltIMG: "Contracts Icon",
        cardLink: "/contratos"
    },
    {
        cardNumber: 5,
        cardSlug: "users",
        cardName: "Usuarios",
        cardIMG: UserIconRoute,
        cardAltIMG: "Users Icon",
        cardLink: "/usuarios"
    },
]

const theme = createTheme({

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    homeTitle: {
        paddingTop: 90,
        paddingBottom: 30,
        fontWeight: 800
    },

    icons: {
        textAlign: 'center',
    }
});

const Home = () => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]
    const [role, setRole] = useState(null)

    useEffect(() => {
        if(!!jwtToken) {
            const payload = decodeToken(jwtToken)
            setRole(payload.sub.role)
        }
    }, [jwtToken])

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Home'
    }, [])

    useEffect(() => {
        //console.log("role: ", role)
    }, [role])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Typography variant="h3" align="center" style={theme.homeTitle}>Menú Principal</Typography>
            </Container>
            <Container sx={{ py: 8 }} maxWidth="lg">
                <Grid 
                    container 
                    spacing={4}
                >
                    {role && cards.map((card) => {
                        return role[card.cardSlug] ? (<Grid item key={card.cardNumber} xs={12} sm={4} md={4}>
                                <Link
                                    to={card.cardLink}
                                    style={{ textDecoration: "none", color: "inherit" }}
                                >
                                    <Card
                                        sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                                    >
                                        <CardActionArea>
                                            <CardMedia
                                                height="200"
                                                sx={{
                                                    backgroundColor: (theme) =>
                                                        theme.palette.grey[200],
                                                }}
                                            >
                                                <div style={theme.icons}>
                                                    <img
                                                        src={card.cardIMG}
                                                        alt={card.cardAltIMG}
                                                    />
                                                </div>
                                            </CardMedia>
                                            <CardContent>
                                                <Container>
                                                    <Typography gutterBottom variant="h5" component="div" align="center" style={{ fontWeight: 800 }}>{card.cardName}</Typography>
                                                </Container>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </Grid>) : null
                    }
                    
                    
                    
                    /* (
                        <Grid item key={card.cardNumber} xs={12} sm={4} md={4}>
                            <Link
                                to={card.cardLink}
                                style={{ textDecoration: "none", color: "inherit" }}
                            >
                                <Card
                                    sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            height="200"
                                            sx={{
                                                backgroundColor: (theme) =>
                                                    theme.palette.grey[200],
                                            }}
                                        >
                                            <div style={theme.icons}>
                                                <img
                                                    src={card.cardIMG}
                                                    alt={card.cardAltIMG}
                                                />
                                            </div>
                                        </CardMedia>
                                        <CardContent>
                                            <Container>
                                                <Typography gutterBottom variant="h5" component="div" align="center" style={{ fontWeight: 800 }}>{card.cardName}</Typography>
                                            </Container>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ) */)}
                </Grid>
            </Container>
        </ThemeProvider>
    );
}

export default Home;