import React from 'react';
import { 
    BrowserRouter,
    Route,
    Routes,
    Navigate
} from 'react-router-dom';
import { decodeToken } from "react-jwt";

//Pages imports
import Login from './pages/index'
import Home from './pages/home'
import Quotations from './pages/quotations'

import Items from './pages/items'
import NewItem from './pages/new_item'

import Clients from './pages/clients'
import NewClient from './pages/new_client'

import NewQuotationStepper from './pages/new_quotation'
import QuotationDetails from './pages/quotation_details'

import Contracts from './pages/contracts'
import NewContract from './pages/new_contract'
import ContractDetails from './pages/contract_details';

import Users from './pages/users'
import NewUser from './pages/new_user'

import VerifyUser from './pages/verify_user';
import RecoveryPassword from './pages/recovery_password'

export const RequireAuth = ({ children, redirectTo }) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    if(!jwtToken && window.location.pathname === '/') return children
    if(jwtToken && window.location.pathname === '/') return <Navigate to={redirectTo} />
    if(!jwtToken) return <Navigate to={redirectTo} />
    return children
}

export const IsAllow = ({ children, section }) => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]
    const payload = decodeToken(jwtToken)

    if(!payload.sub.role[section]) return <Navigate to={'/'} />
    return children
}

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                {/* ===================================
                        Login & Home Routes
                ====================================== */}
                <Route 
                    exact 
                    path='/' 
                    element={
                        <RequireAuth redirectTo={"/home"}>
                            <Login />
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/home'
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <Home />
                        </RequireAuth>
                    }  
                />

                {/* ===================================
                        Verify User & Change Password
                ====================================== */}
                <Route 
                    exact 
                    path='/cambiar-contrasena' 
                    element={<VerifyUser />} 
                />

                <Route 
                    exact 
                    path='/recuperar-contrasena' 
                    element={<RecoveryPassword />} 
                />

                {/* ===================================
                        Items Routes
                ====================================== */}
                <Route 
                    exact 
                    path='/items' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'items'}>
                                <Items />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/items/nuevo' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'items'}>
                                <NewItem />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/items/actualizar/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'items'}>
                                <NewItem />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />  

                {/* ===================================
                        Customer Routes
                ====================================== */}
                <Route 
                    exact 
                    path='/clientes' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'customers'}>
                                <Clients />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/cliente/nuevo' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'customers'}>
                                <NewClient />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/cliente/actualizar/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'customers'}>
                                <NewClient />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />                

                {/* ===================================
                        Quotation Routes
                ====================================== */}
                <Route 
                    exact 
                    path='/cotizaciones' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'quotations'}>
                                <Quotations />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/cotizaciones/nueva' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'quotations'}>
                                <NewQuotationStepper />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/cotizaciones/actualizar/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'quotations'}>
                                <NewQuotationStepper />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/cotizaciones/detalles/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'quotations'}>
                                <QuotationDetails />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />

                {/* ===================================
                        Contract Routes
                ====================================== */}
                <Route 
                    exact 
                    path='/contratos' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'contracts'}>
                                <Contracts />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/contratos/nuevo' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'contracts'}>
                                <NewContract />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/contratos/actualizar/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'contracts'}>
                                <NewContract />
                            </IsAllow>
                        </RequireAuth>
                    } 
                /> 
                <Route 
                    exact 
                    path='/contratos/detalles/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'contracts'}>
                                <ContractDetails />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />

                {/* ===================================
                        Users Routes
                ====================================== */}
                <Route 
                    exact 
                    path='/usuarios' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'users'}>
                                <Users />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/usuarios/nuevo' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'users'}>
                                <NewUser />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
                <Route 
                    exact 
                    path='/usuarios/actualizar/:id' 
                    element={
                        <RequireAuth redirectTo={"/"}>
                            <IsAllow section={'users'}>
                                <NewUser />
                            </IsAllow>
                        </RequireAuth>
                    } 
                />
            </Routes>
        </BrowserRouter>
    );
}

export default Router
