import React, { useState, useEffect } from "react";

import * as Auth from "../api/auth"

//Material UI imports
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Alerts from '../components/alerts'

//React Hook Form imports
import { useForm } from "react-hook-form";

//Assets imports
import LogoInovaELISA from "../assets/imgs/ELISA.png";

//CSS imports
import "./../assets/css/fonts.css"

import { useNavigate } from 'react-router-dom';

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')

//To add a new color, write its information in the pallete section
const theme = createTheme({
  palette: {
    independece: {
      main: '#5C5470',
      contrastText: '#FFFFFF',
    },
  },

  typography: {
    fontFamily: [
      "Montserrat-Regular"
    ].join(",")
  }
});

const RecoveryPassword = () => {
  let navigate = useNavigate();

  // Variables to Alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [dataAlert, setDataAlert] = useState({
    message: '',
    type: ''
  })
  const changeStateAlert = () => {
    setOpenAlert(!openAlert)
  }

  const emailField = React.useRef(null);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
  }

  useEffect(() => {
    document.title = 'ELISA By Inova Evento | Recuperar contraseña'
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item xs={8} justify="center" alignItems="center">
            <img
              src={LogoInovaELISA}
              alt="Logo Inova Evento"
              style={{ width: "35vh" }}
            />
          </Grid>

          <Typography component="h1" variant="h4" style={{ textAlign: 'center' }}>
            Ingresa tu correo electrónico
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              color="independece"
              inputRef={emailField}
              {...register("email", {
                required: "Correo electrónico requerido.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Dirección de correo electrónico inválida.",
                },
              })}
              error={!!errors?.email}
              helperText={errors?.email ? errors.email.message : null}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                backgroundColor: "#5C5470"
              }}
            >
              Recuperar
            </Button>
          </Box>
        </Box>

        <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
      </Container>
    </ThemeProvider>
  );
};

export default RecoveryPassword;
