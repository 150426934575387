import React, { useEffect, useState } from 'react'

//Material UI imports
import {
    Typography,
    Container,
    Stack,
    Box,
    TextField,
    IconButton,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    CssBaseline,
    Tooltip
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Assets imports
import AddIconRoute from "./../../assets/icons/AddButton.png"
import DeleteIconRoute from "./../../assets/icons/Delete.png"
import UpdateIconRoute from "./../../assets/icons/Update.png";

//CSS imports
import "./../../assets/css/fonts.css"
import './../../assets/css/styles.css'

//Services import
import * as ItemsService from '../../api/items'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es-mx')


const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 30,
        paddingBottom: 30,
        fontWeight: 800
    },

    avatarActions: {
        backgroundColor: "transparent",
        color: "black",
        height: 24,
        width: 24,
    },

    buttonAddPersonalizedItem: {
        backgroundColor: "#5C5470",
        align: "right",
        marginBottom: 10,
    },

    alignInputToRight: {
        textAlign: "right !important"
    },

    tableCellPadding: {
        paddingBottom: 0,
        paddingTop: 0
    },
});

const NewContractStepTwo = ({
    comercialDetails,
    setComercialDetails,
    paymentDetails,
    setPaymentDetails,
    paymentsList,
    setPaymentsList,
    id,
    openAlert,
    setOpenAlert,
    dataAlert,
    setDataAlert,
    openLoader,
    setOpenLoader,
    textLoader,
    setTextLoader,
    quotationWithDetails,
    setQuotationWithDetails
}) => {
    useEffect(() => {
        generatePaymentDetailsOnLoad()
    }, [])

    //Variable for enable/disable fields
    const [enableFields, setEnabledFields] = useState(true)

    // First paymentAmmount
    const [firstPayment, setFirstPayment] = useState({
        percentage: true,
        amount: false,
    })


    const editTotalEventCost = () => {

        enableFields ? setEnabledFields(false) : setEnabledFields(true)
    }

    const generatePaymentDetailsOnLoad = async () => {
        var payment = []

        const primerPago = comercialDetails.totalEventCost * (paymentDetails.prepaymentPercentage / 100)

        payment.push({
            ...paymentsList[0],
            paymentAmount: primerPago,
            paymentDueDate: paymentsList.length !== 0 ? moment(paymentsList[0].paymentDueDate).utc() : null
        })

        const restante = comercialDetails.totalEventCost - primerPago

        const pagosRestantes = paymentDetails.paymentsAmount - 1

        for (var i = 0; i < pagosRestantes; i++) {
            payment.push({
                ...paymentsList[i + 1],
                paymentAmount: restante / pagosRestantes,
                paymentDueDate: moment(paymentsList[i + 1].paymentDueDate).utc()
            })
        }

        setPaymentsList(payment)
    }

    const generatePaymentDetails = (value, type) => {
        var payment = []

        if (type === 'paymentsAmount') {
            //console.log(value)
            const primerPago = value > 1 ? comercialDetails.totalEventCost * (paymentDetails.prepaymentPercentage / 100) : comercialDetails.totalEventCost

            if (value > 1) {
                try {
                    const prepaymentAmount = ("" + primerPago).split(".")[0] + "." + ("" + primerPago).split(".")[1].slice(0, 2)

                    const percentageAllDecimals = ("" + ((primerPago * 100) / comercialDetails.totalEventCost)).split(".")
                    const percentage = percentageAllDecimals[0] + "." + percentageAllDecimals[1].slice(0, 2)

                    setPaymentDetails({
                        ...paymentDetails,
                        paymentsAmount: value,
                        prepaymentAmount: parseFloat(prepaymentAmount.replaceAll(",", "")),
                        prepaymentPercentage: parseFloat(percentage.replaceAll(",", ""))
                    })

                    payment.push({
                        paymentAmount: primerPago,
                        paymentDueDate: null
                    })

                    const restante = comercialDetails.totalEventCost - primerPago

                    const pagosRestantes = value - 1

                    for (var i = 0; i < pagosRestantes; i++) {
                        payment.push({
                            paymentAmount: restante / pagosRestantes,
                            paymentDueDate: null
                        })
                    }
                } catch (error) {
                    setPaymentDetails({
                        ...paymentDetails,
                        paymentsAmount: value,
                        prepaymentAmount: '0',
                        prepaymentPercentage: '0'
                    })
                }
            } else if (value === 1) {
                //console.log("100%")
                setPaymentDetails({
                    ...paymentDetails,
                    paymentsAmount: value,
                    prepaymentAmount: primerPago,
                    prepaymentPercentage: '100'
                })

                payment.push({
                    paymentAmount: primerPago,
                    paymentDueDate: null
                })
            }

        } else if (type === 'prepaymentPercentage') {
            const primerPago = comercialDetails.totalEventCost * (value / 100)
            var prepaymentAmount = 0
            try {
                prepaymentAmount = ("" + primerPago).split(".")[0] + "." + ("" + primerPago).split(".")[1].slice(0, 2)
            } catch (error) {
                prepaymentAmount = primerPago + ".00"
            }
            //console.log(primerPago, prepaymentAmount)

            setPaymentDetails({
                ...paymentDetails,
                prepaymentPercentage: value,
                prepaymentAmount: parseFloat(prepaymentAmount.replaceAll(",", ""))
            })

            payment.push({
                paymentAmount: primerPago,
                paymentDueDate: null
            })

            const restante = comercialDetails.totalEventCost - primerPago

            const pagosRestantes = paymentDetails.paymentsAmount - 1

            for (var j = 0; j < pagosRestantes; j++) {
                payment.push({
                    paymentAmount: restante / pagosRestantes,
                    paymentDueDate: null
                })
            }
        } else if (type === 'prepaymentAmount') {
            const primerPago = value

            var percentage = 0
            try {
                const percentageAllDecimals = ("" + ((value * 100) / comercialDetails.totalEventCost)).split(".")
                percentage = percentageAllDecimals[0] + "." + percentageAllDecimals[1].slice(0, 2)
            } catch (error) {
                percentage = ((value * 100) / comercialDetails.totalEventCost) + ".00"
            }


            setPaymentDetails({
                ...paymentDetails,
                prepaymentAmount: value,
                prepaymentPercentage: parseFloat(percentage.replaceAll(",", ""))
            })

            payment.push({
                paymentAmount: primerPago,
                paymentDueDate: null
            })

            const restante = comercialDetails.totalEventCost - primerPago

            const pagosRestantes = paymentDetails.paymentsAmount - 1

            for (var j = 0; j < pagosRestantes; j++) {
                payment.push({
                    paymentAmount: restante / pagosRestantes,
                    paymentDueDate: null
                })
            }
        } else if (type === 'totalEventCost') {
            const primerPago = value * (paymentDetails.prepaymentPercentage / 100)

            setPaymentDetails({
                ...paymentDetails,
                prepaymentAmount: primerPago
            })

            payment.push({
                paymentAmount: primerPago,
                paymentDueDate: null
            })

            const restante = value - primerPago

            const pagosRestantes = paymentDetails.paymentsAmount - 1

            for (var k = 0; k < pagosRestantes; k++) {
                payment.push({
                    paymentAmount: restante / pagosRestantes,
                    paymentDueDate: null
                })
            }
        }

        let paymentWithIds = []
        payment.forEach((element, index) => {
            paymentWithIds.push({
                ...payment[index],
                paymentNumber: index
            })
        })

        setPaymentsList(paymentWithIds)
    }

    const updatePaymentsDueDate = (event, index, value) => {
        event.preventDefault()

        let payments = paymentsList

        // console.log(value)
        // console.log(payments)

        let updatePaymentDueDate = []

        for (var i in payments) {
            //console.log(paymentsList[i])

            if (parseInt(i) === index) {
                updatePaymentDueDate.push({
                    ...payments[i],
                    paymentDueDate: value
                })
                continue
            }

            updatePaymentDueDate.push({
                ...payments[i]
            })

        }

        //console.log(paymentsList)

        setPaymentsList(updatePaymentDueDate)
    }

    const updatePaymentsQty = (event, index, value) => {
        event.preventDefault()

        let payments = paymentsList

        let updatePaymentQty = []

        for (var i in payments) {
            if (parseInt(i) === index) {
                updatePaymentQty.push({
                    ...payments[i],
                    paymentAmount: parseFloat(value)
                })
                continue
            }

            updatePaymentQty.push({
                ...payments[i],
            })
        }

        //console.log(updatePaymentQty)

        setPaymentsList(updatePaymentQty)
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container>
                <Typography variant="h4" align="center" style={theme.newQuotationTitle}>Detalles comerciales</Typography>
            </Container>
            <Container maxWidth='xl' sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, pt: 4 }}>

                {/* COSTS */}
                <Container>
                    <TableContainer>
                        <Table size="small" padding="checkbox">
                            <TableBody >
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left' }}>
                                        <Typography variant="h5" sx={{ marginBottom: "0.5rem" }}><strong>Total de invitados</strong></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6" sx={{ marginBottom: "0.5rem" }}><strong>{ comercialDetails.totalGuests }</strong></Typography>
                                    </TableCell>
                                </TableRow>

                                {/* Adults */}
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left' }}>
                                        <Typography variant="h5"><strong>Adultos</strong></Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Cantidad<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="adultsAmount"
                                            name="AdultsAmount"
                                            size="small"
                                            color="independece"
                                            type="number"
                                            placeholder="0"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            value={comercialDetails.adultsAmount ? comercialDetails.adultsAmount : null}
                                            onChange={(e) => {
                                                setComercialDetails({
                                                    ...comercialDetails,
                                                    adultsAmount: parseInt(e.target.value)
                                                })
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Costo extra<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="adultExtraCost"
                                            name="AdultExtraCost"
                                            size="small"
                                            color="independece"
                                            placeholder="0.00"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={comercialDetails.adultExtraCost ? comercialDetails.adultExtraCost : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        adultExtraCost: data.valueParse
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                    comercialDetails.includeIVA
                                        ? <TableRow>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">Costo con IVA</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">{Number(comercialDetails.adultExtraCost * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                }
                                {/* Youngs */}
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left' }}>
                                        <Typography variant="h5"><strong>Jovenes</strong></Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Cantidad{comercialDetails.teensAmount > 0 ? <span className="text-danger">*</span> : ''}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="teensAmount"
                                            name="teensAmount"
                                            size="small"
                                            color="independece"
                                            type="number"
                                            placeholder="0"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1
                                                },
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            value={comercialDetails.teensAmount ? comercialDetails.teensAmount : 0}
                                            onChange={(e) => {
                                                if (parseInt(e.target.value) > 0) {

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        teensAmount: parseInt(e.target.value)
                                                    })
                                                } else {
                                                    // console.log('entró')
                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        teensAmount: parseInt(e.target.value),
                                                        teenExtraCost: 0
                                                    })
                                                }
                                                // setComercialDetails({
                                                //     ...comercialDetails,
                                                //     teensAmount: parseInt(e.target.value)
                                                // })
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Costo extra{comercialDetails.teensAmount > 0 ? <span className="text-danger">*</span> : ''}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            disabled={comercialDetails.teensAmount === 0}
                                            fullWidth
                                            margin="dense"
                                            id="teenExtraCost"
                                            name="TeenExtraCost"
                                            size="small"
                                            color="independece"
                                            placeholder="0.00"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1
                                                },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={comercialDetails.teenExtraCost ? comercialDetails.teenExtraCost : 0}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        teenExtraCost: data.valueParse
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                    comercialDetails.includeIVA
                                        ? <TableRow>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">Costo con IVA</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">{Number(comercialDetails.teenExtraCost * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                }

                                {/* Kids */}
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left' }}>
                                        <Typography variant="h5"><strong>Niños</strong></Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Cantidad{comercialDetails.childrenAmount > 0 ? <span className="text-danger">*</span> : ''}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="childrenAmount"
                                            name="ChildrenAmount"
                                            size="small"
                                            color="independece"
                                            type="number"
                                            placeholder="0"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1
                                                },
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            value={comercialDetails.childrenAmount ? comercialDetails.childrenAmount : 0}
                                            onChange={(e) => {
                                                if (parseInt(e.target.value) > 0) {

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        childrenAmount: parseInt(e.target.value)
                                                    })
                                                } else {
                                                    //console.log('entró')
                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        childrenAmount: parseInt(e.target.value),
                                                        childrenExtraCost: 0
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Costo extra{comercialDetails.childrenAmount > 0 ? <span className="text-danger">*</span> : ''}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            disabled={comercialDetails.childrenAmount === 0}
                                            fullWidth
                                            margin="dense"
                                            id="childrenExtraCost"
                                            name="ChildrenExtraCost"
                                            size="small"
                                            color="independece"
                                            placeholder="0.00"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={comercialDetails.childrenExtraCost ? comercialDetails.childrenExtraCost : 0}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        childrenExtraCost: data.valueParse
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                    comercialDetails.includeIVA
                                        ? <TableRow>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">Costo con IVA</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">{Number(comercialDetails.childrenExtraCost * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                }

                                {/* Event details */}
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left' }}>
                                        <Typography variant="h5"><strong>Detalles Evento</strong></Typography>
                                    </TableCell>
                                </TableRow>
                                {/* Event duration */}
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Duración del evento<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="eventDuration"
                                            name="EventDuration"
                                            size="small"
                                            color="independece"
                                            type="number"
                                            placeholder="0"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                endAdornment: <InputAdornment>horas</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            value={comercialDetails.eventDuration ? comercialDetails.eventDuration : null}
                                            onChange={(e) => {
                                                setComercialDetails({
                                                    ...comercialDetails,
                                                    eventDuration: parseInt(e.target.value)
                                                })
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Costo por hora adicional<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="extraDurationCost"
                                            name="ExtraDurationCost"
                                            size="small"
                                            color="independece"
                                            placeholder="0.00"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={comercialDetails.extraDurationCost ? comercialDetails.extraDurationCost : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        extraDurationCost: data.valueParse
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {/* Waiter/Bar duration */}
                                {/* <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Horas de Meseros/Bar<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="waiterBarDuration"
                                            name="WaiterBarDuration"
                                            size="small"
                                            color="independece"
                                            type="number"
                                            placeholder="0"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                endAdornment: <InputAdornment>horas</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            value={comercialDetails.waiterBarServiceTime ? comercialDetails.waiterBarServiceTime : null}
                                            onChange={(e) => {
                                                setComercialDetails({
                                                    ...comercialDetails,
                                                    waiterBarServiceTime: parseInt(e.target.value)
                                                })
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Costo por hora adicional<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="waiterBarExtraDurationCost"
                                            name="WaiterBarExtraDurationCost"
                                            size="small"
                                            color="independece"
                                            placeholder="0.00"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={comercialDetails.waiterBarExtraHourCost ? comercialDetails.waiterBarExtraHourCost : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        waiterBarExtraHourCost: data.valueParse
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow> */}
                                {/* Deposit amount */}
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                        <Typography variant="h6">Monto deposito<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left' }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="depositAmount"
                                            name="DepositAmount"
                                            size="small"
                                            color="independece"
                                            placeholder="0.00"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    step: 1
                                                },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={paymentDetails.depositAmount ? paymentDetails.depositAmount : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    setPaymentDetails({
                                                        ...paymentDetails,
                                                        depositAmount: data.valueParse
                                                    })
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>

                {/* PAYMENT DETAILS */}
                <Container style={{}}>
                    <TableContainer style={{}}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right', paddingBottom: 0, paddingTop: 0 }}>
                                        <Typography variant="h6"><strong>Costo total del evento</strong><span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left', display: 'flex', paddingBottom: 0, paddingTop: 0 }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            disabled={enableFields}
                                            className={enableFields ? 'inputsDisabled' : ''}
                                            size="small"
                                            color="independece"
                                            InputProps={
                                                enableFields
                                                    ?
                                                    {
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        endAdornment: <Tooltip title="Editar total">
                                                                <IconButton
                                                                    onClick={editTotalEventCost}
                                                                >
                                                                    <img
                                                                        alt="Update Icon"
                                                                        src={UpdateIconRoute}
                                                                        style={theme.avatarActions}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>,
                                                        sx: {
                                                            "& input": {
                                                                textAlign: "right"
                                                            },
                                                            backgroundColor: "#a9a9a9 !important",
                                                            webkitTextFillColor: "#000000 !important"

                                                        }
                                                    }
                                                    :
                                                    {
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        endAdornment: <Tooltip title="Editar total">
                                                                <IconButton
                                                                    onClick={editTotalEventCost}
                                                                >
                                                                    <img
                                                                        alt="Update Icon"
                                                                        src={UpdateIconRoute}
                                                                        style={theme.avatarActions}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>,
                                                        sx: {
                                                            "& input": {
                                                                textAlign: "right"
                                                            },
                                                        }
                                                    }
                                            }
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={comercialDetails.totalEventCost ? comercialDetails.totalEventCost : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)
                                                    setComercialDetails({
                                                        ...comercialDetails,
                                                        totalEventCost: data.value
                                                    })

                                                    if (
                                                        data.valueParse !== 0 &&
                                                        paymentDetails.paymentsAmount !== 0 &&
                                                        paymentDetails.prepaymentPercentage !== 0
                                                    ) {
                                                        generatePaymentDetails(data.valueParse, 'totalEventCost')
                                                    }
                                                }
                                            }}
                                        />
                                        {/* <Tooltip title="Editar total">
                                            <IconButton
                                                onClick={editTotalEventCost}
                                            >
                                                <img
                                                    alt="Update Icon"
                                                    src={UpdateIconRoute}
                                                    style={theme.avatarActions}
                                                />
                                            </IconButton>
                                        </Tooltip> */}
                                    </TableCell>
                                </TableRow>
                                {
                                    comercialDetails.includeIVA
                                        ? <TableRow>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">Costo total del evento + IVA</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="body2">{Number(comercialDetails.totalEventCost * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                }
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right', paddingBottom: 0, paddingTop: 0 }}>
                                        <Typography variant="h6">Cantidad de pagos<span className="text-danger">*</span></Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left', paddingBottom: 0, paddingTop: 0 }}>
                                        <TextField
                                            disabled={paymentDetails.paymentAmount === 1}
                                            fullWidth
                                            margin="dense"
                                            id="paymentsAmount"
                                            name="PaymentsAmount"
                                            size="small"
                                            color="independece"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    max: 5,
                                                    step: 1
                                                },
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseInt(e.target.value) > 5) {
                                                    e.target.value = 5
                                                } else if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={paymentDetails.paymentsAmount ? paymentDetails.paymentsAmount : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseInt(e.target.value)
                                                }

                                                setPaymentDetails({
                                                    ...paymentDetails,
                                                    paymentsAmount: data.value
                                                })

                                                if (
                                                    comercialDetails.totalEventCost !== 0 &&
                                                    data.valueParse !== 0 &&
                                                    paymentDetails.prepaymentPercentage !== 0
                                                ) {
                                                    generatePaymentDetails(data.valueParse, 'paymentsAmount')
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right', paddingBottom: 0, paddingTop: 0 }}>
                                        <FormControlLabel
                                            style={{ marginRight: 0 }}
                                            value="% Anticipo"
                                            control={
                                                <Checkbox
                                                    checked={firstPayment.percentage}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setFirstPayment({
                                                                percentage: true,
                                                                amount: false
                                                            })
                                                        } else {
                                                            setFirstPayment({
                                                                percentage: false,
                                                                amount: true
                                                            })
                                                        }
                                                    }}
                                                    disabled={paymentDetails.paymentsAmount === 1}
                                                />
                                            }
                                            label={
                                                <Typography variant="h6">
                                                    % Anticipo
                                                    {firstPayment.percentage ? <span className="text-danger">*</span> : null}
                                                </Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left', paddingBottom: 0, paddingTop: 0 }}>
                                        <TextField
                                            disabled={!firstPayment.percentage || paymentDetails.paymentsAmount === 1}
                                            fullWidth
                                            margin="dense"
                                            id="prepaymentPercentage"
                                            name="PrepaymentPercentage"
                                            size="small"
                                            color="independece"
                                            placeholder="0"
                                            InputProps={{
                                                endAdornment: <InputAdornment>%</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value) > 100) {
                                                    e.target.value = 100
                                                } else if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={paymentDetails.prepaymentPercentage ? paymentDetails.prepaymentPercentage : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    if (
                                                        data.value[data.value.length - 1] !== '.' &&
                                                        comercialDetails.totalEventCost !== 0 &&
                                                        data.valueParse !== 0 &&
                                                        paymentDetails.paymentsAmount !== 0
                                                    ) {
                                                        generatePaymentDetails(data.valueParse, 'prepaymentPercentage')
                                                    } else {
                                                        setPaymentDetails({
                                                            ...paymentDetails,
                                                            prepaymentPercentage: data.value
                                                        })
                                                    }
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right', paddingBottom: 0, paddingTop: 0 }}>
                                        <FormControlLabel
                                            style={{ marginRight: 0 }}
                                            value="$ Anticipo"
                                            control={
                                                <Checkbox
                                                    checked={firstPayment.amount}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setFirstPayment({
                                                                percentage: false,
                                                                amount: true
                                                            })
                                                        } else {
                                                            setFirstPayment({
                                                                percentage: true,
                                                                amount: false
                                                            })
                                                        }
                                                    }}
                                                    disabled={paymentDetails.paymentsAmount === 1}
                                                />
                                            }
                                            label={
                                                <Typography variant="h6">
                                                    $ Anticipo
                                                    {firstPayment.amount ? <span className="text-danger">*</span> : null}
                                                </Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'left', paddingBottom: 0, paddingTop: 0 }}>
                                        <TextField
                                            disabled={!firstPayment.amount || paymentDetails.paymentsAmount === 1}
                                            fullWidth
                                            margin="dense"
                                            id="prepaymentAmount"
                                            name="PrepaymentAmount"
                                            size="small"
                                            color="independece"
                                            placeholder="0"
                                            InputProps={{
                                                startAdornment: <InputAdornment>$</InputAdornment>,
                                                sx: {
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (parseFloat(e.target.value < 1)) {
                                                    e.target.value = 1
                                                }
                                            }}
                                            value={paymentDetails.prepaymentAmount ? paymentDetails.prepaymentAmount : null}
                                            onChange={(e) => {
                                                const data = {
                                                    value: e.target.value,
                                                    valueParse: parseFloat(e.target.value),
                                                    regex: /^\d+(\.\d{0,2})?$/
                                                }

                                                if (data.regex.test(data.value)) {
                                                    //console.log(data)

                                                    if (
                                                        data.value[data.value.length - 1] !== '.' &&
                                                        comercialDetails.totalEventCost !== 0 &&
                                                        data.valueParse !== 0 &&
                                                        paymentDetails.paymentsAmount !== 0
                                                    ) {
                                                        generatePaymentDetails(data.valueParse, 'prepaymentAmount')
                                                    } else {
                                                        setPaymentDetails({
                                                            ...paymentDetails,
                                                            prepaymentAmount: data.value
                                                        })
                                                    }
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                    comercialDetails.includeIVA
                                        ? <TableRow>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="subtitle1">$ Anticipo + IVA</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }} style={{ textAlign: 'right' }}>
                                                <Typography variant="subtitle1" style={{ width: '60%' }}>{Number(paymentDetails.prepaymentAmount * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Container style={{ textAlign: 'center' }}>
                        <Typography variant="h6" style={{ width: '100%' }}>Detalle de pagos</Typography>
                        <Container style={{ width: '90%', padding: 0 }}>
                            <TableContainer style={{ textAlign: 'right' }}>
                                <Table size="small">
                                    <TableBody style={{ verticalAlign: 'top', textAlign: 'right' }}>
                                        {/* DEFINITION OF TABLE SIZE FOR PAYMENTS TABLE */}
                                        {paymentsList.map((payment, index) => (
                                            <div>
                                                <TableRow key={index} style={{ width: '100%' }}>
                                                    {/* Payment Identifier */}
                                                    <TableCell style={{ paddingBottom: '5px' }}>
                                                        <Typography
                                                            variant='subtitle2'
                                                            style={{ width: '3.5vw' }}
                                                        >
                                                            Pago {index + 1}:
                                                        </Typography>
                                                    </TableCell>

                                                    {/* Payment Amount */}
                                                    <TableCell style={{ paddingBottom: '5px', textAlign: 'right', width: "100%" }}>
                                                        {
                                                            index === 0
                                                                ? comercialDetails.includeIVA
                                                                    ? Number(payment.paymentAmount * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                    : Number(payment.paymentAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                : <TextField
                                                                    style={{ width: '100%'}}
                                                                    margin="dense"
                                                                    size="small"
                                                                    color="independece"
                                                                    placeholder="0"
                                                                    InputProps={
                                                                        {
                                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                            sx: {
                                                                                "& input": {
                                                                                    textAlign: "right"
                                                                                },
                                                                            }
                                                                        }
                                                                    }
                                                                    value={
                                                                        payment.paymentAmount 
                                                                        ? isNaN((Math.round((payment.paymentAmount + Number.EPSILON) * 100) / 100)) 
                                                                            ? payment.paymentAmount
                                                                            : (Math.round((payment.paymentAmount + Number.EPSILON) * 100) / 100)
                                                                        : null
                                                                    }
                                                                    onInput={(e) => {
                                                                        if (parseFloat(e.target.value < 1)) {
                                                                            e.target.value = 1
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {

                                                                        const data = {
                                                                            value: e.target.value,
                                                                            valueParse: parseFloat(e.target.value),
                                                                            regex: /^\d+(\.\d{0,2})?$/
                                                                        }


                                                                        if (data.regex.test(data.value)) {
                                                                            //console.log(data)

                                                                            if (
                                                                                data.value[data.value.length - 1] !== '.' &&
                                                                                data.valueParse !== 0
                                                                            ) {
                                                                                updatePaymentsQty(e, index, data.valueParse)
                                                                            } else {
                                                                                const update = []

                                                                                for (var i in paymentsList) {

                                                                                    const payment = paymentsList[i]

                                                                                    if (index === parseInt(i)) {
                                                                                        payment.paymentAmount = data.value
                                                                                    }

                                                                                    update.push(payment)
                                                                                }

                                                                                setPaymentsList(update)
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                        }
                                                    </TableCell>

                                                    {/* Due Date */}
                                                    <TableCell style={{ paddingBottom: '5px' }}>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            margin="dense"
                                                            size="small"
                                                            color="independece"
                                                            type="date"
                                                            InputProps={{ inputProps: { min: "0000-01-01", max: "9999-12-31" } }}
                                                            value={payment.paymentDueDate ? moment(payment.paymentDueDate).format("YYYY-MM-DD") : ''}
                                                            onChange={(e) => { updatePaymentsDueDate(e, index, e.target.value) }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    comercialDetails.includeIVA
                                                        ? <TableRow>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography variant="body2" style={{ width: 'max-content' }}> Pago { index + 1 } con IVA:</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }} style={{ width: '100%' }}>
                                                                <Typography variant="body2">{Number(payment.paymentAmount * 1.16).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                }
                                            </div>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </Container>

                    {/* Checkbox IVA */}
                    <Container style={{ textAlign: 'left' }}>
                        <FormControlLabel
                            value="Incluir IVA en el contrato"
                            control={
                                <Checkbox
                                    checked={comercialDetails.includeIVA}
                                    onChange={(e) => {
                                        setComercialDetails({
                                            ...comercialDetails,
                                            includeIVA: e.target.checked
                                        })
                                    }}
                                />
                            }
                            label="Incluir IVA en el contrato"
                        />
                    </Container>
                </Container>
            </Container>
        </ThemeProvider>
    );
}

export default NewContractStepTwo;