import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

//Material UI imports
import { Typography, CssBaseline, Container, Stack, Button, Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Alerts from '../components/alerts.jsx'
import LoaderScreen from "../components/loaderScreen.jsx"

//React Hook Form imports
import { useForm } from "react-hook-form";

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Components imports
import NavBar from "../components/NavBarComponent/NavBarComponent.jsx"

//Services import
import * as UserServices from '../api/users.jsx'

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 30,
        fontWeight: 800
    },
});

const NewUser = (props) => {
    let navigate = useNavigate();
    const { id } = useParams();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    const [action, setAction] = useState('Nuevo Usuario')
    const [item, setItem] = useState([])
    const [roles, setRoles] = useState([])

    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Nuevo usuario'

        const getRoles = async () => {
            const response = await UserServices.getUsersRole()

            if(response.success) {
                //console.log(response)
                setRoles(response.data)
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        setOpenLoader(true)
        getRoles()
    }, [])


    const [openConfirmation, setOpenConfirmation] = useState({
        open: false,
        email: '',
        password: ''
    })
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const onSubmit = async (data) => {
        //console.log(data)

        if(action === 'Nuevo Usuario') {
            setOpenLoader(true)

            const response = await UserServices.CreateUser(data)
            //console.log(response)

            if(response.success) {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Usuario agregado exitosamente',
                    type: 'success'
                })

                setOpenConfirmation({
                    open: true,
                    email: data.email,
                    password: response.data.password
                })
                //navigate("/usuarios");
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        } else {
            setOpenLoader(true)

            const response = await UserServices.UpdateUser(data, id)
            //console.log(response)

            if(response.success) {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Usuario actualizado correctamente',
                    type: 'success'
                })

                navigate("/usuarios");
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }
    }

    useEffect(() => {
        if (!!roles) {
            const getUser = async () => {
                const response = await UserServices.GetUserById(id)

                if(response.success) {
                    //console.log(response)
                    setAction('Actualizar usuario')
                    
                    setItem(response.data)
                } else {
                    setOpenAlert(true)
                    setDataAlert({
                        message: response.message,
                        type: 'error'
                    })
                    //console.log(response)
                }

                setOpenLoader(false)
            }

            if( id && id !== undefined && id !== null) {
                document.title = 'ELISA By Inova Evento | Actualizar usuario'

                setOpenLoader(true)
                getUser()
            }
        }
    }, [roles])

    useEffect(() => {
        if(!!item) {
            reset({
                ...item
            })
        }
    }, [item])

    const copyPassword = async () => {
        try {
            await navigator.clipboard.writeText(openConfirmation.password);
            setDataAlert({
                message: 'Contraseña copiada al portapapeles' ,
                type: 'info' 
            })
            setOpenAlert(true)
        } catch (err) {
            setDataAlert({
                message: 'Error al copiar la contraseña al portapapeles' ,
                type: 'error' 
            })
            setOpenAlert(true)
        }
    }

    const hidePassword = (password) => {
        return '*'.repeat(password.length)
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>{ action }</Typography>
            </Container>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                <Container >
                    <Box sx={{ maxWidth: {xs: '100%', sm: '50%', md: '50%'} }}>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Nombre<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.name}
                                margin="normal"
                                fullWidth
                                id="name"
                                name="name"
                                size="small"
                                color="independece"
                                type="text"
                                {...register("name", {
                                    required: "El nombre del cliente es obligatorio."
                                })}
                                error={!!errors?.name}
                                helperText={errors?.name ? errors.name.message : null}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Apellidos<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.lastName}
                                margin="normal"
                                fullWidth
                                id="lastName"
                                name="lastName"
                                size="small"
                                color="independece"
                                type="text"
                                {...register("lastName", {
                                    required: "Los apellidos del cliente son obligatorios."
                                })}
                                error={!!errors?.lastName}
                                helperText={errors?.lastName ? errors.lastName.message : null}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Correo<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.email}
                                margin="normal"
                                fullWidth
                                id="email"
                                name="email"
                                size="small"
                                color="independece"
                                type="text"
                                {...register("email", {
                                    required: "El correo electrónico del cliente es obligatorio.",
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Dirección de correo electrónico inválida.",
                                    },
                                })}
                                error={!!errors?.email}
                                helperText={errors?.email ? errors.email.message : null}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Rol<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.roleId || ''}
                                margin="normal"
                                fullWidth
                                select
                                id="roleId"
                                name="roleId"
                                size="small"
                                color="independece"
                                {...register("roleId", {
                                    required: "El rol del cliente es obligatorio."
                                })}
                                onChange={(e) => setItem({...item, roleId: e.target.value})}
                                error={!!errors?.roleId}
                                helperText={errors?.roleId ? errors.roleId.message : null}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.roleId} value={role.roleId}>{role.role}</MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                    </Box>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="right"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                backgroundColor: "#5C5470",
                                
                            }}
                        >
                            Guardar
                        </Button>
                    </Stack>
                </Container>
            </Box>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />

            <Dialog
                open={openConfirmation.open}
                onClose={() => {
                    setOpenConfirmation({
                        open: false,
                        email: '',
                        password: ''
                    })
                    navigate("/usuarios");
                }}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Nuevo usuario
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ textAlign: 'center' }}>El usuario <strong>{openConfirmation.email}</strong> ha sido creado exitosamente.</p>
                        <span>Contraseña</span>
                        <span>{hidePassword(openConfirmation.password)}</span>
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button 
                        onClick={() => {
                            setOpenConfirmation({
                                open: false,
                                email: '',
                                password: ''
                            })
                            navigate("/usuarios");
                        }}
                    >
                        Cerrar
                    </Button>
                    <Button onClick={copyPassword}>Copiar Contraseña</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default NewUser;