import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

//Material UI imports
import { Typography, CssBaseline, Container, Stack, Button, Box, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Alerts from '../components/alerts'
import LoaderScreen from "../components/loaderScreen"

//React Hook Form imports
import { useForm } from "react-hook-form";

//CSS imports
import "./../assets/css/fonts.css"
import "./../assets/css/styles.css"

//Components imports
import NavBar from "./../components/NavBarComponent/NavBarComponent.jsx"

//Services import
import * as ItemsService from '../api/items'

const theme = createTheme({

    palette: {
        independece: {
            main: '#5C5470',
            contrastText: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: ['Montserrat-Regular'].join(",")
    },

    newQuotationTitle: {
        paddingTop: 100,
        paddingBottom: 30,
        fontWeight: 800
    },
});

const NewItem = (props) => {
    let navigate = useNavigate();
    const { id } = useParams();

    // Variables to Alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [dataAlert, setDataAlert] = useState({
        message: '',
        type: ''
    })
    const changeStateAlert = () => {
        setOpenAlert(!openAlert)
    }

    // Valirable to loader screen
    const [openLoader, setOpenLoader] = useState(false)
    const [textLoader, setTextLoader] = useState('')

    const [action, setAction] = useState('Nuevo Item')
    const [item, setItem] = useState({
        itemId: null,
        name: '',
        description: '',
        price: '',
        discount: '',
        quantityAvailable: ''
    })
    
    useEffect(() => {
        document.title = 'ELISA By Inova Evento | Nuevo item'
        
        const getItem = async () => {
            const response = await ItemsService.GetItemsById(id)

            if(response.success) {
                //console.log(response)
                setAction('Actualizar Item')
                setItem({
                    itemId: response.data[0].itemId,
                    name: response.data[0].name,
                    description: response.data[0].description,
                    price: response.data[0].price,
                    discount: response.data[0].disccount ? response.data[0].disccount : '',
                    quantityAvailable: response.data[0].quantityAvailable ? response.data[0].quantityAvailable : ''
                })
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }

        if( id && id !== undefined && id !== null) {
            document.title = 'ELISA By Inova Evento | Actualizar item'

            setOpenLoader(true)
            getItem()
        }
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm();
    const saveItem = async () => {
        if(action === 'Nuevo Item') {
            if(
                item.name !== '' &&
                item.price !== ''
            ) {
                setOpenLoader(true)

                const response = await ItemsService.CreateItems(item)

                if(response.success) {
                    setItem({
                        itemId: null,
                        name: '',
                        description: '',
                        price: '',
                        discount: '',
                        quantityAvailable: ''
                    })

                    setOpenAlert(true)
                    setDataAlert({
                        message: 'Item agregado exitosamente',
                        type: 'error'
                    })

                    navigate("/items");
                } else {
                    setOpenAlert(true)
                    setDataAlert({
                        message: response.message,
                        type: 'error'
                    })
                    //console.log(response)
                }

                setOpenLoader(false)
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                })
            }
        } else {
            if(
                (item.itemId !== '' || item.itemId !== null) &&
                item.name !== '' &&
                item.price !== ''
            ) {
                setOpenLoader(true)

                const response = await ItemsService.UpdateItems(item, item.itemId)

                if(response.success) {
                    setItem({
                        itemId: null,
                        name: '',
                        description: '',
                        price: '',
                        discount: '',
                        quantityAvailable: ''
                    })

                    setOpenAlert(true)
                    setDataAlert({
                        message: 'Item actualizado correctamente',
                        type: 'success'
                    })

                    navigate("/items");
                } else {
                    setOpenAlert(true)
                    setDataAlert({
                        message: response.message,
                        type: 'error'
                    })
                    //console.log(response)
                }

                setOpenLoader(false)
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Existen campos obligatorios sin llenar',
                    type: 'error'
                })
            }
        }
    }

    /* Disable or Enabled an item 
        * Type (Number)
            * 1 Enabled
            * 2 Disabled
    */
    const disabledEnabled = async (type, id) => {
        setOpenLoader(true)

        if(type === 1) {
            // De momento no se habilitaran los items
        } else if(type === 0) {
            const response = await ItemsService.DeleteItems(id)

            if(response.success) {
                setOpenAlert(true)
                setDataAlert({
                    message: 'Item eliminado exitosamente',
                    type: 'success'
                })
                
                navigate('/items')
            } else {
                setOpenAlert(true)
                setDataAlert({
                    message: response.message,
                    type: 'error'
                })
                //console.log(response)
            }

            setOpenLoader(false)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container>
                <Typography variant="h3" align="center" style={theme.newQuotationTitle}>{ action }</Typography>
            </Container>
            <Box component="form" onSubmit={handleSubmit(saveItem)} noValidate sx={{ mt: 1 }}>
                <Container >
                    <Box sx={{ maxWidth: {xs: '100%', sm: '50%', md: '50%'} }}>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Nombre<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.name}
                                margin="normal"
                                fullWidth
                                id="name"
                                name="name"
                                size="small"
                                color="independece"
                                type="text"
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        name: e.target.value
                                    })
                                }}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Descripción</Typography>
                            <TextField
                                value={item.description}
                                margin="normal"
                                fullWidth
                                id="description"
                                name="description"
                                size="small"
                                color="independece"
                                multiline
                                rows={4}
                                onInput = {(e) =>{
                                    e.target.value = (e.target.value).toString().slice(0,2500)
                                }}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        description: e.target.value
                                    })
                                }}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Costo<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.price}
                                margin="normal"
                                fullWidth
                                id="cost"
                                name="cost"
                                size="small"
                                color="independece"
                                type="text"
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        price: e.target.value
                                    })
                                }}
                            />
                        </Stack>
                        {/* <Stack direction="column" spacing={2} style={{ marginTop: 15 }}>
                            <Typography variant="h6">Descuento</Typography>
                            <TextField
                                value={item.discount}
                                margin="normal"
                                fullWidth
                                id="descuento"
                                name="descuento"
                                size="small"
                                color="independece"
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        discount: e.target.value
                                    })
                                }}
                            />
                        </Stack> */}
                        {/* <Stack direction="column" spacing={2} style={{ marginTop: 15,  }}>
                            <Typography variant="h6">Inventario Disponible<span className="text-danger">*</span></Typography>
                            <TextField
                                value={item.quantityAvailable}
                                margin="normal"
                                fullWidth
                                id="inv_available"
                                name="inv_available"
                                size="small"
                                color="independece"
                                type="select"
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        quantityAvailable: e.target.value
                                    })
                                }}
                            />
                        </Stack> */}
                    </Box>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="right"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                backgroundColor: "#5C5470",
                                
                            }}
                        >
                            Guardar
                        </Button>

                        {item.itemId && <Button
                            variant="text"
                            style={{
                                color: "#5C5470",
                            }}
                            onClick={() => disabledEnabled(0, item.itemId)}
                        >
                            Eliminar
                        </Button>}
                    </Stack>
                </Container>
            </Box>

            <Alerts changeState={changeStateAlert} open={openAlert} data={dataAlert} />
            <LoaderScreen open={openLoader} text={textLoader} />
        </ThemeProvider>
    );
}

export default NewItem;